<template>
  <div class="card">
    <div class="title">{{ $t('settings.title') }}</div>
    <hr>
    <div class="card-content">
      <div>
        <h3 class="mb-2">Announcement-Ping (Discord)</h3>
        <label class="switch">
          <input id="discordUpdate" type="checkbox" v-bind="setting.notify" @click="sendDiscord">
          <span class="slider"></span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Settings",
  data() {
    return {
      setting: {
        locale: 'de',
        notify: false,
      }
    }
  },
  mounted() {
    fetch(
        this.$t('host') + '/info/usersettings',
        {
          method: 'GET',
          headers: {
            'Authorization': localStorage.accesstoken,
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
    )
        .then(res => {
              if (res.status == 200) {
                return res.json();
              } else {
                console.log(res.text())
              }
            }
        )
        .then(res => {
              this.setting = JSON.parse(res)
            }
        )
        .catch((e) => {
              console.log(e)
            }
        )
  },
  methods: {
    sendDiscord() {
      console.log(this.setting)
    }
  }
}
</script>

<style scoped>

</style>
