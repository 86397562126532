<template>
  <div class="card">
    <div class="title-row">
      <div class="title">{{ $t('home.chart.title') }}</div>
      <form v-on:submit.prevent @submit="loadMembers">
        <input v-model="query" id="key" type="text"
               class="px-4 py-2 text-gray-700 bg-white border rounded-md sm:mx-2 dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
               placeholder="Search...">
      </form>
    </div>
    <hr>
    <div class="card-content">
      <div class="cardsContainer">
        <router-link :to="'/user/' + member.id" class="usercard" :key="member.id" v-for="member in members">
          <img :src="member.avatarUrl" alt="">
          <div class="userName">{{ member.username }}</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientOverview",
  components: {},
  data() {
    return {
      query: '',
      members: [],
    }
  },
  methods: {
    loadMembers() {
      fetch(
          this.$t('host') + '/admin/client?query=' + this.query,
          {
            method: 'GET',
            mode: 'cors',
            headers: {
              'Authorization': localStorage.accesstoken,
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
          }
      )
          .then(
              res => {
                return res.json();
              }
          )
          .then(
              res => {
                this.members = res.members
              }
          )
          .catch(
              (e) => {
                console.log(e)
              }
          )
    }
  },
  mounted() {
    this.loadMembers()
  },
}
</script>

<style scoped>
.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.usercard {
  display: flex;
  width: 32%;
  align-items: center;
  gap: 1rem;
  background-color: #1F2235;
  border-radius: 5px;
  transition: 500ms;
}

.usercard:hover {

  background-color: var(--red);
}

.usercard .userName {
  margin-right: 1rem;
}

.usercard img {
  height: 5em;
  width: 5em;
  border-radius: 5px;
  margin: 1rem;
}

.title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-row > *:last-child {
  margin: 1rem;
}

</style>