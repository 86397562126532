<template>

  <ErrorAlert ref="errorAlert"/>

  <div class="card">
    <div class="title">{{ $t('tickets.view.title', [this.$route.params.id]) }}</div>
    <hr>
    <div class="card-content">
      <div class="chatcontainer">
        <div :class="['message', message.userID == did ? 'self' : 'other']" :key="message.id" v-for="message in messages">
          <img :src="message.avatarUrl" alt="">
          <div class="name text-gray-300">{{message.username}}</div>
          <div class="messagecontent" v-html="strip(message.message.replaceAll('\n', '<br>'))"></div>
          <div class="timestamp text-gray-300">{{ new Date(message.timestamp).toLocaleDateString("de-DE") + " " + new Date(message.timestamp).toLocaleTimeString("de-DE") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ErrorAlert from "@/components/ui/alerts/ErrorAlert";

export default {
  name: "Ticket",
  components: {
    ErrorAlert
  },
  data(){
    return{
      messages: [],
      did: "",
    }
  },
  methods: {

    strip(text){
      const SCRIPT_REGEX = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
      while (SCRIPT_REGEX.test(text)) {
        text = text.replace(SCRIPT_REGEX, "");
      }
      return text;
    },
    loadData(){
      fetch(this.$t('host') + '/ticket/' + this.$route.params.id, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Authorization': localStorage.accesstoken,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      }).then(res => {
        if(res.status === 200) {
          return res.json();
        }
        if(res.status === 401){
          this.$refs.errorAlert.showNow(this.$t('tickets.view.unauthorised'))
          console.log(res.text())
        }
        if(res.status === 404){
          this.$refs.errorAlert.showNow(this.$t('tickets.view.notfound'))
        }
      })
          .then(res => {
            this.messages = res.messages;
          }).catch((e) => {
        console.log(e)
      })
    }
  },
  mounted() {
    this.loadData()
    if (localStorage.userdata) {
      let user = JSON.parse(localStorage.userdata)
      this.did = user.id
    }
  }
}
</script>

<style scoped>

.chatcontainer{
  display: flex;
  flex-flow: column;
  margin-right: 2rem;
}

.message{
  margin: 1rem;
  border-radius: 5px;
  width: fit-content;
  height: fit-content;
  max-width: 60%;
  padding: 5px;
  transform: translateX(2rem);

}

.message.self{
  background-color: green;
  align-self: end;
  transform: translateX(0);
}

.message.other{
  background-color: gray;
}

.message.other img{
  position: absolute;
  top: 0;
  left: -3rem;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 100%;

}
.message.self img{
  position: absolute;
  top: 0;
  right: -3rem;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 100%;

}

.message.other:before{
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  left: 0;
  content: '';
  margin-left: -12px;
  border-top: 15px solid gray;
  border-left: 15px solid transparent;
}

.message.self:before{
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  right: 0;
  content: '';
  margin-right: -12px;
  border-top: 15px solid green;
  border-right: 15px solid transparent;
}

.name{
  font-weight: bolder;
  margin-bottom: 0.3rem;
  font-size: 1rem;
}
.timestamp{
  margin-top: 0.4rem;
  font-size: 0.75rem;
}

</style>