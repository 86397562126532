<template>
  <LicenseEditModal @update="loadData" :res="res" ref="modal"/>
  <div class="infocontainer">
    <InfoCard title="Key" :content="key" icon="fa-key" color="#068BBF"></InfoCard>
    <InfoCard title="Client" :content="client ? client.username : plClient" icon="fa-user" color="#76767A"></InfoCard>
    <InfoCard title="Plugin" :content="plName" icon="fa-file-code-o" color="#EB912C"></InfoCard>
    <InfoCard title="Gültigkeit" :content="renderTime(expiry)" icon="fa-calendar" color="#923A2C"></InfoCard>
    <InfoCard title="Beschreibung" :content=plDesc  icon="fa-file-text" color="#445364"></InfoCard>
  </div>

  <div class="card">
    <div class="title">Validierungen</div>
    <hr>
    <div class="card-content">
      <div id="graph"></div>
    </div>
  </div>


  <div class="card">
    <div class="title">IP-Adressen</div>
    <hr>
    <div class="card-content">
      <div class="flex flex-col">
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block py-2 min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden shadow-md sm:rounded-lg">

              <table class="min-w-full dark">
                <thead class=" dark:bg-gray-700">
                <tr>
                  <th class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase dark:text-gray-400"
                      scope="col">
                    IP-Adresse
                  </th>
                  <th class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase dark:text-gray-400"
                      scope="col">
                    Verwendungen
                  </th>
                  <th class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase dark:text-gray-400"
                      scope="col">
                    Letzte Verwendung
                  </th>
                </tr>
                </thead>
                <tbody>

                <tr
                    v-show="!this.ipAdresses"
                    class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                    <div class="unloaded"></div>
                  </td>
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400 key ">
                    <div class="unloaded"></div>
                  </td>
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                    <div class="unloaded"></div>
                  </td>
                </tr>


                <tr v-for="ipadress in this.ipAdresses"
                    :key="ipadress.ip"
                    class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">

                  <td
                      class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400 key ">{{
                      ipadress.ip
                                                                                                        }}
                  </td>
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                    {{ ipadress.uses }}
                  </td>
                  <td
                      class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400 key ">{{
                      renderTime(ipadress.lastuse)
                                                                                                        }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>

  <button class="edit" @click="openEdit"><i class="fa fa-pencil"></i></button>
</template>

<script>
import ApexCharts from "apexcharts";
import InfoCard from "@/components/ui/InfoCard";
import LicenseEditModal from "@/components/admin/LicenseEditModal";

export default {
  name: "License",
  components: {
    InfoCard,
    LicenseEditModal
  },
  data() {
    return {
      chartData: [],
      key: '???',
      plClient: '???',
      client: undefined,
      plName: '???',
      expiry: -1,
      plDesc: '???',
      plBound: true,
      ipAdresses: undefined,
      res: {},
    }
  },
  methods: {
    renderTime(timestamp){
      if(timestamp == -1){
        return this.$t('util.license.infinite')
      }else if(timestamp == 0){
        return this.$t('util.license.locked')
      }else{
        return new Date(timestamp).toLocaleDateString('de-DE') + ' ' + new Date(timestamp).toLocaleTimeString('de-DE')
      }
    },
    async drawChrat() {
      var options = {
        colors: ['#FF4A57', '#E91E63', '#9C27B0'],
        series: [{
          name: 'Validierungen',
          data: this.chartData,
        }],
        chart: {
          type: 'area',
          stacked: false,
          height: 350,
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
          },
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100]
          },
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return val;
            },
          },
          title: {
            text: 'Validierungen'
          },
        },
        xaxis: {
          type: 'datetime',
        },
        tooltip: {
          shared: false,
          theme: 'dark',
          y: {
            formatter: function (val) {
              return val
            }
          },
          x: {
            show: true,
            format: 'dd MMM HH:00',
          }
        },
        theme: {},
        background: 'rgba(0, 0, 0, 0)'
      };

      const chart = new ApexCharts(document.querySelector("#graph"), options);
      await chart.render();

      document.querySelector("svg").style.background = "transparent"
    },
    async loadData() {
      console.log("loading data....")
      await fetch(this.$t('host') + '/license/' + this.$route.params.key, {
        method: 'GET',
        headers: {
          'Authorization': localStorage.accesstoken,
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }).then(res => {
        return res.json();
      })
          .then(res => {
            this.plClient = res.plClient;
            this.client = res.client;
            this.plName = res.plName;
            this.expiry = res.expiry;
            this.plDesc = res.plDesc;
            this.plBound = res.plBound;
            this.key = res.key;
            this.res = res;

            try {
              this.plDesc = JSON
                  .stringify(JSON.parse(this.plDesc), null, 2)
                  .replaceAll("\n", "<br>")
                  .replaceAll("   ", "&ensp;")
            } catch (e) {
              console.log("no json")
            }
          }).catch((e) => {
            console.log(e)
          })
    },
    async loadGraph() {
      console.log("loading data....")
      await fetch(this.$t('host') + '/licenseData/' + this.$route.params.key, {
        method: 'GET',
        headers: {
          'Authorization': localStorage.accesstoken,
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }).then(res => {
        return res.json();
      })
          .then(res => {
            this.chartData = res.graphData;
            this.ipAdresses = res.ipAdresses;

            try {
              this.plDesc = JSON
                  .stringify(JSON.parse(this.plDesc), null, 2)
                  .replaceAll("\n", "<br>")
                  .replaceAll("   ", "&ensp;")
            } catch (e) {
              console.log("no json")
            }

            this.drawChrat()
          }).catch((e) => {
            console.log(e)
          })
    },
    openEdit() {
      this.$refs.modal.show();
    }
  },
  mounted() {
    this.loadData();
    this.loadGraph();
  }
}
</script>

<style scoped>

.infocontainer{
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.infocontainer *{
  flex: 49%;
}

.edit {
  position: fixed;
  right: 3rem;
  bottom: 3rem;
  background-color: var(--red);
  font-size: 1.5rem;
  border-radius: 5rem;
  transition: 250ms;
  width: 4rem;
  height: 4rem;
}

.edit:hover{
  transform: scale(1.1);
}

</style>