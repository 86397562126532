<template>
  <div class="card">
    <div class="title">Imprint</div>
    <hr>
    <div class="card-content">

      <div class='impressum'>
        <h1>Impressum</h1>
        <p>Angaben gemäß § 5 TMG</p>
        <p>
          Lukas Schmidt <br>
          E.-v.-K. Str. 28<br>
          27755 DEL <br>
          Kontakt: contact@primeapi.de <br>
        </p>
        <p><strong>Haftungsausschluss: </strong><br><br><strong>Haftung für Inhalte</strong><br><br>
          Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und
          Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1
          TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG
          sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
          überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen
          zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon
          unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
          Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte
          umgehend entfernen.<br><br><strong>Haftung für Links</strong><br><br>
          Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben.
          Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
          Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden
          zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt
          der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
          konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden
          wir derartige Links umgehend entfernen.<br><br><strong>Urheberrecht</strong><br><br>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
          Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen
          des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und
          Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf
          dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
          werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung
          aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden
          wir derartige Inhalte umgehend entfernen.<br><br><br><br>
          Datenschutz erklärung finden Sie unten. <br><br>

          <strong>Verantwortlicher (gem. §55 RstV) für journalistisch-redaktionelle Inhalte</strong><br>
          Lukas Schmidt <br>
          E.-v.-K.<br>
          27755 DEL <br>
          Kontakt: contact@primeapi.de <br>
          <br><br>
          <strong>Information gemäß § 36 VSBG</strong>
          Gemäß § 36 VSBG (Verbraucherstreitbeilegungsgesetz – Gesetz über die alternative Streitbeilegung in
          Verbrauchersachen) erklärt der Betreiber dieser Website:<br>

          Wir sind weder bereit noch verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
          teilzunehmen.<br>


        </p><br>
        Website Impressum erstellt durch <a href="https://www.impressum-generator.de">impressum-generator.de</a> von der
        <a href="https://www.kanzlei-hasselbach.de/">Kanzlei Hasselbach</a>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="title">DSGVO</div>
    <hr>
    <div class="card-content">

      <div class='impressum'>
        <h1>Datenschutzerklärung</h1>
        <h2>Einleitung</h2>
        <p>Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären, welche Arten Ihrer
           personenbezogenen Daten (nachfolgend auch kurz als "Daten“ bezeichnet) wir zu welchen Zwecken und in welchem
           Umfang verarbeiten. Die Datenschutzerklärung gilt für alle von uns durchgeführten Verarbeitungen
           personenbezogener Daten, sowohl im Rahmen der Erbringung unserer Leistungen als auch insbesondere auf unseren
           Webseiten, in mobilen Applikationen sowie innerhalb externer Onlinepräsenzen, wie z.B. unserer
           Social-Media-Profile (nachfolgend zusammenfassend bezeichnet als "Onlineangebot“).</p>
        <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
        <p>Stand: 5. November 2021</p>
        <h2>Inhaltsübersicht</h2>
        <ul class="index">
          <li><a class="index-link" href="#m14">Einleitung</a></li>
          <li><a class="index-link" href="#m3">Verantwortlicher</a></li>
          <li><a class="index-link" href="#mOverview">Übersicht der Verarbeitungen</a></li>
          <li><a class="index-link" href="#m13">Maßgebliche Rechtsgrundlagen</a></li>
          <li><a class="index-link" href="#m27">Sicherheitsmaßnahmen</a></li>
          <li><a class="index-link" href="#m12">Löschung von Daten</a></li>
          <li><a class="index-link" href="#m134">Einsatz von Cookies</a></li>
          <li><a class="index-link" href="#m225">Bereitstellung des Onlineangebotes und Webhosting</a></li>
          <li><a class="index-link" href="#m182">Kontakt- und Anfragenverwaltung</a></li>
          <li><a class="index-link" href="#m136">Präsenzen in sozialen Netzwerken (Social Media)</a></li>
          <li><a class="index-link" href="#m15">Änderung und Aktualisierung der Datenschutzerklärung</a></li>
          <li><a class="index-link" href="#m10">Rechte der betroffenen Personen</a></li>
          <li><a class="index-link" href="#m42">Begriffsdefinitionen</a></li>
        </ul>
        <h2 id="m3">Verantwortlicher</h2>
        <p>Lukas Schmidt<br>E.-v.-K.<br>27755 DEL</p>
        <p>E-Mail-Adresse: <a href="mailto:contact@primeapi.de">contact@primeapi.de</a>.</p>
        <p>Impressum: <a href="https://cp.primeapi.de/imprint.php"
                         target="_blank">https://cp.primeapi.de/imprint.php</a>.</p>
        <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
        <p>Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die Zwecke ihrer Verarbeitung zusammen
           und verweist auf die betroffenen Personen.</p>
        <h3>Arten der verarbeiteten Daten</h3>
        <ul>
          <li>Bestandsdaten (z.B. Namen, Adressen).</li>
          <li>Inhaltsdaten (z.B. Eingaben in Onlineformularen).</li>
          <li>Kontaktdaten (z.B. E-Mail, Telefonnummern).</li>
          <li>Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).</li>
          <li>Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten).</li>
        </ul>
        <h3>Kategorien betroffener Personen</h3>
        <ul>
          <li>Kommunikationspartner.</li>
          <li>Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
        </ul>
        <h3>Zwecke der Verarbeitung</h3>
        <ul>
          <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
          <li>Direktmarketing (z.B. per E-Mail oder postalisch).</li>
          <li>Feedback (z.B. Sammeln von Feedback via Online-Formular).</li>
          <li>Marketing.</li>
          <li>Kontaktanfragen und Kommunikation.</li>
        </ul>
        <h3 id="m13">Maßgebliche Rechtsgrundlagen</h3>
        <p>Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO, auf deren Basis wir personenbezogene
           Daten verarbeiten. Bitte nehmen Sie zur Kenntnis, dass neben den Regelungen der DSGVO nationale
           Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland gelten können. Sollten ferner im Einzelfall
           speziellere Rechtsgrundlagen maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung mit.</p>
        <ul>
          <li><strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO)</strong> - Die betroffene Person hat ihre
                                                                               Einwilligung in die Verarbeitung der sie
                                                                               betreffenden personenbezogenen Daten für
                                                                               einen spezifischen Zweck oder mehrere
                                                                               bestimmte Zwecke gegeben.
          </li>
          <li><strong>Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO)</strong> - Die
                                                                                                                 Verarbeitung
                                                                                                                 ist für
                                                                                                                 die
                                                                                                                 Erfüllung
                                                                                                                 eines
                                                                                                                 Vertrags,
                                                                                                                 dessen
                                                                                                                 Vertragspartei
                                                                                                                 die
                                                                                                                 betroffene
                                                                                                                 Person
                                                                                                                 ist,
                                                                                                                 oder
                                                                                                                 zur
                                                                                                                 Durchführung
                                                                                                                 vorvertraglicher
                                                                                                                 Maßnahmen
                                                                                                                 erforderlich,
                                                                                                                 die auf
                                                                                                                 Anfrage
                                                                                                                 der
                                                                                                                 betroffenen
                                                                                                                 Person
                                                                                                                 erfolgen.
          </li>
          <li><strong>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO)</strong> - Die Verarbeitung ist zur
                                                                                         Wahrung der berechtigten
                                                                                         Interessen des Verantwortlichen
                                                                                         oder eines Dritten
                                                                                         erforderlich, sofern nicht die
                                                                                         Interessen oder Grundrechte und
                                                                                         Grundfreiheiten der betroffenen
                                                                                         Person, die den Schutz
                                                                                         personenbezogener Daten
                                                                                         erfordern, überwiegen.
          </li>
        </ul>
        <p><strong>Nationale Datenschutzregelungen in Deutschland</strong>: Zusätzlich zu den Datenschutzregelungen der
                                                                          Datenschutz-Grundverordnung gelten nationale
                                                                          Regelungen zum Datenschutz in Deutschland.
                                                                          Hierzu gehört insbesondere das Gesetz zum
                                                                          Schutz vor Missbrauch personenbezogener Daten
                                                                          bei der Datenverarbeitung
                                                                          (Bundesdatenschutzgesetz – BDSG). Das BDSG
                                                                          enthält insbesondere Spezialregelungen zum
                                                                          Recht auf Auskunft, zum Recht auf Löschung,
                                                                          zum Widerspruchsrecht, zur Verarbeitung
                                                                          besonderer Kategorien personenbezogener Daten,
                                                                          zur Verarbeitung für andere Zwecke und zur
                                                                          Übermittlung sowie automatisierten
                                                                          Entscheidungsfindung im Einzelfall
                                                                          einschließlich Profiling. Des Weiteren regelt
                                                                          es die Datenverarbeitung für Zwecke des
                                                                          Beschäftigungsverhältnisses (§ 26 BDSG),
                                                                          insbesondere im Hinblick auf die Begründung,
                                                                          Durchführung oder Beendigung von
                                                                          Beschäftigungsverhältnissen sowie die
                                                                          Einwilligung von Beschäftigten. Ferner können
                                                                          Landesdatenschutzgesetze der einzelnen
                                                                          Bundesländer zur Anwendung gelangen.</p>
        <h2 id="m27">Sicherheitsmaßnahmen</h2>
        <p>Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung des Stands der Technik, der
           Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der
           unterschiedlichen Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung der Rechte und Freiheiten
           natürlicher Personen geeignete technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes
           Schutzniveau zu gewährleisten.</p>
        <p>Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit von
           Daten durch Kontrolle des physischen und elektronischen Zugangs zu den Daten als auch des sie betreffenden
           Zugriffs, der Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben
           wir Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten, die Löschung von Daten und
           Reaktionen auf die Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den Schutz
           personenbezogener Daten bereits bei der Entwicklung bzw. Auswahl von Hardware, Software sowie Verfahren
           entsprechend dem Prinzip des Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche
           Voreinstellungen.</p>
        <h2 id="m12">Löschung von Daten</h2>
        <p>Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen Vorgaben gelöscht, sobald deren zur
           Verarbeitung erlaubten Einwilligungen widerrufen werden oder sonstige Erlaubnisse entfallen (z.B. wenn der
           Zweck der Verarbeitung dieser Daten entfallen ist oder sie für den Zweck nicht erforderlich sind).</p>
        <p>Sofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke erforderlich
           sind, wird deren Verarbeitung auf diese Zwecke beschränkt. D.h., die Daten werden gesperrt und nicht für
           andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus handels- oder steuerrechtlichen Gründen
           aufbewahrt werden müssen oder deren Speicherung zur Geltendmachung, Ausübung oder Verteidigung von
           Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person erforderlich
           ist.</p>
        <p>Unsere Datenschutzhinweise können ferner weitere Angaben zu der Aufbewahrung und Löschung von Daten
           beinhalten, die für die jeweiligen Verarbeitungen vorrangig gelten.</p>
        <h2 id="m134">Einsatz von Cookies</h2>
        <p>Cookies sind Textdateien, die Daten von besuchten Websites oder Domains enthalten und von einem Browser auf
           dem Computer des Benutzers gespeichert werden. Ein Cookie dient in erster Linie dazu, die Informationen über
           einen Benutzer während oder nach seinem Besuch innerhalb eines Onlineangebotes zu speichern. Zu den
           gespeicherten Angaben können z.B. die Spracheinstellungen auf einer Webseite, der Loginstatus, ein Warenkorb
           oder die Stelle, an der ein Video geschaut wurde, gehören. Zu dem Begriff der Cookies zählen wir ferner
           andere Technologien, die die gleichen Funktionen wie Cookies erfüllen (z.B., wenn Angaben der Nutzer anhand
           pseudonymer Onlinekennzeichnungen gespeichert werden, auch als "Nutzer-IDs" bezeichnet)</p>
        <p><strong>Die folgenden Cookie-Typen und Funktionen werden unterschieden:</strong></p>
        <ul>
          <li><strong>Temporäre Cookies (auch: Session- oder Sitzungs-Cookies):</strong>&nbsp;Temporäre Cookies werden
                                                                                        spätestens gelöscht, nachdem ein
                                                                                        Nutzer ein Online-Angebot
                                                                                        verlassen und seinen Browser
                                                                                        geschlossen hat.
          </li>
          <li><strong>Permanente Cookies:</strong>&nbsp;Permanente Cookies bleiben auch nach dem Schließen des Browsers
                                                  gespeichert. So kann beispielsweise der Login-Status gespeichert oder
                                                  bevorzugte Inhalte direkt angezeigt werden, wenn der Nutzer eine
                                                  Website erneut besucht. Ebenso können die Interessen von Nutzern, die
                                                  zur Reichweitenmessung oder zu Marketingzwecken verwendet werden, in
                                                  einem solchen Cookie gespeichert werden.
          </li>
          <li><strong>First-Party-Cookies:</strong>&nbsp;First-Party-Cookies werden von uns selbst gesetzt.</li>
          <li><strong>Third-Party-Cookies (auch: Drittanbieter-Cookies)</strong>: Drittanbieter-Cookies werden
                                                                                hauptsächlich von Werbetreibenden (sog.
                                                                                Dritten) verwendet, um
                                                                                Benutzerinformationen zu verarbeiten.
          </li>
          <li><strong>Notwendige (auch: essentielle oder unbedingt erforderliche) Cookies:</strong> Cookies können zum
                                                                                                    einen für den
                                                                                                    Betrieb einer
                                                                                                    Webseite unbedingt
                                                                                                    erforderlich sein
                                                                                                    (z.B., um Logins
                                                                                                    oder andere
                                                                                                    Nutzereingaben zu
                                                                                                    speichern oder aus
                                                                                                    Gründen der
                                                                                                    Sicherheit).
          </li>
          <li><strong>Statistik-, Marketing- und Personalisierungs-Cookies</strong>: Ferner werden Cookies im Regelfall
                                                                                   auch im Rahmen der Reichweitenmessung
                                                                                   eingesetzt sowie dann, wenn die
                                                                                   Interessen eines Nutzers oder sein
                                                                                   Verhalten (z.B. Betrachten bestimmter
                                                                                   Inhalte, Nutzen von Funktionen etc.)
                                                                                   auf einzelnen Webseiten in einem
                                                                                   Nutzerprofil gespeichert werden.
                                                                                   Solche Profile dienen dazu, den
                                                                                   Nutzern z.B. Inhalte anzuzeigen, die
                                                                                   ihren potentiellen Interessen
                                                                                   entsprechen. Dieses Verfahren wird
                                                                                   auch als "Tracking", d.h.,
                                                                                   Nachverfolgung der potentiellen
                                                                                   Interessen der Nutzer bezeichnet.
                                                                                   Soweit wir Cookies oder
                                                                                   "Tracking"-Technologien einsetzen,
                                                                                   informieren wir Sie gesondert in
                                                                                   unserer Datenschutzerklärung oder im
                                                                                   Rahmen der Einholung einer
                                                                                   Einwilligung.
          </li>
        </ul>
        <p><strong>Hinweise zu Rechtsgrundlagen: </strong> Auf welcher Rechtsgrundlage wir Ihre personenbezogenen Daten
                                                           mit Hilfe von Cookies verarbeiten, hängt davon ab, ob wir Sie
                                                           um eine Einwilligung bitten. Falls dies zutrifft und Sie in
                                                           die Nutzung von Cookies einwilligen, ist die Rechtsgrundlage
                                                           der Verarbeitung Ihrer Daten die erklärte Einwilligung.
                                                           Andernfalls werden die mithilfe von Cookies verarbeiteten
                                                           Daten auf Grundlage unserer berechtigten Interessen (z.B. an
                                                           einem betriebswirtschaftlichen Betrieb unseres
                                                           Onlineangebotes und dessen Verbesserung) verarbeitet oder,
                                                           wenn der Einsatz von Cookies erforderlich ist, um unsere
                                                           vertraglichen Verpflichtungen zu erfüllen.</p>
        <p><strong>Speicherdauer: </strong>Sofern wir Ihnen keine expliziten Angaben zur Speicherdauer von permanenten
                                           Cookies mitteilen (z. B. im Rahmen eines sog. Cookie-Opt-Ins), gehen Sie
                                           bitte davon aus, dass die Speicherdauer bis zu zwei Jahre betragen kann.</p>
        <p><strong>Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-Out): </strong> Abhängig davon, ob die
                                                                                         Verarbeitung auf Grundlage
                                                                                         einer Einwilligung oder
                                                                                         gesetzlichen Erlaubnis erfolgt,
                                                                                         haben Sie jederzeit die
                                                                                         Möglichkeit, eine erteilte
                                                                                         Einwilligung zu widerrufen oder
                                                                                         der Verarbeitung Ihrer Daten
                                                                                         durch Cookie-Technologien zu
                                                                                         widersprechen (zusammenfassend
                                                                                         als "Opt-Out" bezeichnet). Sie
                                                                                         können Ihren Widerspruch
                                                                                         zunächst mittels der
                                                                                         Einstellungen Ihres Browsers
                                                                                         erklären, z.B., indem Sie die
                                                                                         Nutzung von Cookies
                                                                                         deaktivieren (wobei hierdurch
                                                                                         auch die Funktionsfähigkeit
                                                                                         unseres Onlineangebotes
                                                                                         eingeschränkt werden kann). Ein
                                                                                         Widerspruch gegen den Einsatz
                                                                                         von Cookies zu Zwecken des
                                                                                         Onlinemarketings kann auch
                                                                                         mittels einer Vielzahl von
                                                                                         Diensten, vor allem im Fall des
                                                                                         Trackings, über die Webseiten
          <a href="https://optout.aboutads.info" target="_blank">https://optout.aboutads.info</a> und
          <a href="https://www.youronlinechoices.com/" target="_blank">https://www.youronlinechoices.com/</a> erklärt
                                                                                         werden. Daneben können Sie
                                                                                         weitere Widerspruchshinweise im
                                                                                         Rahmen der Angaben zu den
                                                                                         eingesetzten Dienstleistern und
                                                                                         Cookies erhalten.</p>
        <ul class="m-elements">
          <li><strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
                                                        Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
                                                        Geräte-Informationen, IP-Adressen).
          </li>
          <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
          <li><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO), Berechtigte Interessen
                                                 (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
          </li>
        </ul>
        <h2 id="m225">Bereitstellung des Onlineangebotes und Webhosting</h2>
        <p>Um unser Onlineangebot sicher und effizient bereitstellen zu können, nehmen wir die Leistungen von einem oder
           mehreren Webhosting-Anbietern in Anspruch, von deren Servern (bzw. von ihnen verwalteten Servern) das
           Onlineangebot abgerufen werden kann. Zu diesen Zwecken können wir Infrastruktur- und
           Plattformdienstleistungen, Rechenkapazität, Speicherplatz und Datenbankdienste sowie Sicherheitsleistungen
           und technische Wartungsleistungen in Anspruch nehmen.</p>
        <p>Zu den im Rahmen der Bereitstellung des Hostingangebotes verarbeiteten Daten können alle die Nutzer unseres
           Onlineangebotes betreffenden Angaben gehören, die im Rahmen der Nutzung und der Kommunikation anfallen.
           Hierzu gehören regelmäßig die IP-Adresse, die notwendig ist, um die Inhalte von Onlineangeboten an Browser
           ausliefern zu können, und alle innerhalb unseres Onlineangebotes oder von Webseiten getätigten Eingaben.</p>
        <p><strong>Erhebung von Zugriffsdaten und Logfiles</strong>: Wir selbst (bzw. unser Webhostinganbieter) erheben
                                                                   Daten zu jedem Zugriff auf den Server (sogenannte
                                                                   Serverlogfiles). Zu den Serverlogfiles können die
                                                                   Adresse und Name der abgerufenen Webseiten und
                                                                   Dateien, Datum und Uhrzeit des Abrufs, übertragene
                                                                   Datenmengen, Meldung über erfolgreichen Abruf,
                                                                   Browsertyp nebst Version, das Betriebssystem des
                                                                   Nutzers, Referrer URL (die zuvor besuchte Seite) und
                                                                   im Regelfall IP-Adressen und der anfragende Provider
                                                                   gehören.</p>
        <p>Die Serverlogfiles können zum einen zu Zwecken der Sicherheit eingesetzt werden, z.B., um eine Überlastung
           der Server zu vermeiden (insbesondere im Fall von missbräuchlichen Angriffen, sogenannten DDoS-Attacken) und
           zum anderen, um die Auslastung der Server und ihre Stabilität sicherzustellen.</p>
        <ul class="m-elements">
          <li><strong>Verarbeitete Datenarten:</strong> Inhaltsdaten (z.B. Eingaben in Onlineformularen), Nutzungsdaten
                                                        (z.B. besuchte Webseiten, Interesse an Inhalten,
                                                        Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
                                                        Geräte-Informationen, IP-Adressen).
          </li>
          <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
          <li><strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
          </li>
          <li><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).</li>
        </ul>
        <h2 id="m182">Kontakt- und Anfragenverwaltung</h2>
        <p>Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail, Telefon oder via soziale Medien) sowie im
           Rahmen bestehender Nutzer- und Geschäftsbeziehungen werden die Angaben der anfragenden Personen verarbeitet
           soweit dies zur Beantwortung der Kontaktanfragen und etwaiger angefragter Maßnahmen erforderlich ist.</p>
        <p>Die Beantwortung der Kontaktanfragen sowie die Verwaltung von Kontakt- und Anfragedaten im Rahmen von
           vertraglichen oder vorvertraglichen Beziehungen erfolgt zur Erfüllung unserer vertraglichen Pflichten oder
           zur Beantwortung von (vor)vertraglichen Anfragen und im Übrigen auf Grundlage der berechtigten Interessen an
           der Beantwortung der Anfragen und Pflege von Nutzer- bzw. Geschäftsbeziehungen.</p>
        <ul class="m-elements">
          <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen), Kontaktdaten (z.B. E-Mail,
                                                        Telefonnummern), Inhaltsdaten (z.B. Eingaben in
                                                        Onlineformularen).
          </li>
          <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
          <li><strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation.</li>
          <li><strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit.
                                                 b. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
          </li>
        </ul>
        <h2 id="m136">Präsenzen in sozialen Netzwerken (Social Media)</h2>
        <p>Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und verarbeiten in diesem Rahmen Daten der
           Nutzer, um mit den dort aktiven Nutzern zu kommunizieren oder um Informationen über uns anzubieten.</p>
        <p>Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des Raumes der Europäischen Union verarbeitet
           werden können. Hierdurch können sich für die Nutzer Risiken ergeben, weil so z.B. die Durchsetzung der Rechte
           der Nutzer erschwert werden könnte.</p>
        <p>Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im Regelfall für Marktforschungs- und
           Werbezwecke verarbeitet. So können z.B. anhand des Nutzungsverhaltens und sich daraus ergebender Interessen
           der Nutzer Nutzungsprofile erstellt werden. Die Nutzungsprofile können wiederum verwendet werden, um z.B.
           Werbeanzeigen innerhalb und außerhalb der Netzwerke zu schalten, die mutmaßlich den Interessen der Nutzer
           entsprechen. Zu diesen Zwecken werden im Regelfall Cookies auf den Rechnern der Nutzer gespeichert, in denen
           das Nutzungsverhalten und die Interessen der Nutzer gespeichert werden. Ferner können in den Nutzungsprofilen
           auch Daten unabhängig der von den Nutzern verwendeten Geräte gespeichert werden (insbesondere, wenn die
           Nutzer Mitglieder der jeweiligen Plattformen sind und bei diesen eingeloggt sind).</p>
        <p>Für eine detaillierte Darstellung der jeweiligen Verarbeitungsformen und der Widerspruchsmöglichkeiten
           (Opt-Out) verweisen wir auf die Datenschutzerklärungen und Angaben der Betreiber der jeweiligen
           Netzwerke.</p>
        <p>Auch im Fall von Auskunftsanfragen und der Geltendmachung von Betroffenenrechten weisen wir darauf hin, dass
           diese am effektivsten bei den Anbietern geltend gemacht werden können. Nur die Anbieter haben jeweils Zugriff
           auf die Daten der Nutzer und können direkt entsprechende Maßnahmen ergreifen und Auskünfte geben. Sollten Sie
           dennoch Hilfe benötigen, dann können Sie sich an uns wenden.</p>
        <ul class="m-elements">
          <li><strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B.
                                                        Eingaben in Onlineformularen), Nutzungsdaten (z.B. besuchte
                                                        Webseiten, Interesse an Inhalten, Zugriffszeiten),
                                                        Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
                                                        IP-Adressen).
          </li>
          <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
          <li><strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation, Feedback (z.B. Sammeln von
                                                        Feedback via Online-Formular), Marketing.
          </li>
          <li><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).</li>
        </ul>
        <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
        <ul class="m-elements">
          <li><strong>Twitter:</strong> Soziales Netzwerk; Dienstanbieter: Twitter International Company, One Cumberland
                                        Place, Fenian Street, Dublin 2 D02 AX07, Irland, Mutterunternehmen: Twitter
                                        Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA;
                                        Datenschutzerklärung: <a href="https://twitter.com/de/privacy" target="_blank">https://twitter.com/de/privacy</a>,
                                        (Einstellungen) <a href="https://twitter.com/personalization" target="_blank">https://twitter.com/personalization</a>.
          </li>
        </ul>
        <h2 id="m15">Änderung und Aktualisierung der Datenschutzerklärung</h2>
        <p>Wir bitten Sie, sich regelmäßig über den Inhalt unserer Datenschutzerklärung zu informieren. Wir passen die
           Datenschutzerklärung an, sobald die Änderungen der von uns durchgeführten Datenverarbeitungen dies
           erforderlich machen. Wir informieren Sie, sobald durch die Änderungen eine Mitwirkungshandlung Ihrerseits
           (z.B. Einwilligung) oder eine sonstige individuelle Benachrichtigung erforderlich wird.</p>
        <p>Sofern wir in dieser Datenschutzerklärung Adressen und Kontaktinformationen von Unternehmen und
           Organisationen angeben, bitten wir zu beachten, dass die Adressen sich über die Zeit ändern können und bitten
           die Angaben vor Kontaktaufnahme zu prüfen.</p>
        <h2 id="m10">Rechte der betroffenen Personen</h2>
        <p>Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu, die sich insbesondere aus Art. 15 bis 21
           DSGVO ergeben:</p>
        <ul>
          <li><strong>Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation
                      ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die
                      aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für
                      ein auf diese Bestimmungen gestütztes Profiling. Werden die Sie betreffenden personenbezogenen
                      Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen
                      die Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung
                      einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung
                      steht.</strong></li>
          <li><strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das Recht, erteilte Einwilligungen jederzeit
                                                                  zu widerrufen.
          </li>
          <li><strong>Auskunftsrecht:</strong> Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob
                                               betreffende Daten verarbeitet werden und auf Auskunft über diese Daten
                                               sowie auf weitere Informationen und Kopie der Daten entsprechend den
                                               gesetzlichen Vorgaben.
          </li>
          <li><strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den gesetzlichen Vorgaben das Recht, die
                                                       Vervollständigung der Sie betreffenden Daten oder die
                                                       Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.
          </li>
          <li><strong>Recht auf Löschung und Einschränkung der Verarbeitung:</strong> Sie haben nach Maßgabe der
                                                                                      gesetzlichen Vorgaben das Recht,
                                                                                      zu verlangen, dass Sie betreffende
                                                                                      Daten unverzüglich gelöscht
                                                                                      werden, bzw. alternativ nach
                                                                                      Maßgabe der gesetzlichen Vorgaben
                                                                                      eine Einschränkung der
                                                                                      Verarbeitung der Daten zu
                                                                                      verlangen.
          </li>
          <li><strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das Recht, Sie betreffende Daten, die Sie uns
                                                               bereitgestellt haben, nach Maßgabe der gesetzlichen
                                                               Vorgaben in einem strukturierten, gängigen und
                                                               maschinenlesbaren Format zu erhalten oder deren
                                                               Übermittlung an einen anderen Verantwortlichen zu
                                                               fordern.
          </li>
          <li><strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben unbeschadet eines anderweitigen
                                                                verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs
                                                                das Recht auf Beschwerde bei einer Aufsichtsbehörde,
                                                                insbesondere in dem Mitgliedstaat ihres gewöhnlichen
                                                                Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des
                                                                mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass
                                                                die Verarbeitung der Sie betreffenden personenbezogenen
                                                                Daten gegen die Vorgaben der DSGVO verstößt.
          </li>
        </ul>
        <h2 id="m42">Begriffsdefinitionen</h2>
        <p>In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser Datenschutzerklärung verwendeten
           Begrifflichkeiten. Viele der Begriffe sind dem Gesetz entnommen und vor allem im Art. 4 DSGVO definiert. Die
           gesetzlichen Definitionen sind verbindlich. Die nachfolgenden Erläuterungen sollen dagegen vor allem dem
           Verständnis dienen. Die Begriffe sind alphabetisch sortiert.</p>
        <ul class="glossary">
          <li><strong>Personenbezogene Daten:</strong> "Personenbezogene Daten“ sind alle Informationen, die sich auf
                                                       eine identifizierte oder identifizierbare natürliche Person (im
                                                       Folgenden "betroffene Person“) beziehen; als identifizierbar wird
                                                       eine natürliche Person angesehen, die direkt oder indirekt,
                                                       insbesondere mittels Zuordnung zu einer Kennung wie einem Namen,
                                                       zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung
                                                       (z.B. Cookie) oder zu einem oder mehreren besonderen Merkmalen
                                                       identifiziert werden kann, die Ausdruck der physischen,
                                                       physiologischen, genetischen, psychischen, wirtschaftlichen,
                                                       kulturellen oder sozialen Identität dieser natürlichen Person
                                                       sind.
          </li>
          <li><strong>Verantwortlicher:</strong> Als "Verantwortlicher“ wird die natürliche oder juristische Person,
                                                 Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit
                                                 anderen über die Zwecke und Mittel der Verarbeitung von
                                                 personenbezogenen Daten entscheidet, bezeichnet.
          </li>
          <li><strong>Verarbeitung:</strong> "Verarbeitung" ist jeder mit oder ohne Hilfe automatisierter Verfahren
                                             ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit
                                             personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch
                                             jeden Umgang mit Daten, sei es das Erheben, das Auswerten, das Speichern,
                                             das Übermitteln oder das Löschen.
          </li>
        </ul>
        <p class="seal"><a href="https://datenschutz-generator.de/?l=de"
                           rel="noopener noreferrer nofollow"
                           target="_blank"
                           title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken.">Erstellt mit
                                                                                                            kostenlosem
                                                                                                            Datenschutz-Generator.de
                                                                                                            von Dr.
                                                                                                            Thomas
                                                                                                            Schwenke</a>
        </p>
        <br><br>
        <div id="cookiefirst-policy-page"></div>
        <div>Diese Cookie-Richtlinie wurde erstellt und aktualisiert von der Firma <a href="https://cookiefirst.com">CookieFirst.com</a>.
        </div>
        <div id="cookiefirst-cookies-table"></div>
        <div>Diese Cookie-Tabelle wurde erstellt und aktualisiert von der <a href="https://cookiefirst.com">CookieFirst
                                                                                                            consent
                                                                                                            management
                                                                                                            platform</a>.
        </div>


      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Imprint"
}
</script>

<style scoped>

</style>