<template>
  <div class="smallcards">
    <InfoCard :title="$t('tickets.amount')" :content="amount" icon="fa-ticket"
              color="#E75A7C"></InfoCard>
    <InfoCard :title="$t('tickets.messages')" :content="messages" icon="fa-commenting"
              color="#5E4AE3"></InfoCard>
  </div>

  <TicketsTable :tickets="tickets" />



</template>

<script>
import InfoCard from "@/components/ui/InfoCard";

import TicketsTable from "@/components/tickets/TicketsTable";

export default {
  name: "Tickets",
  components: {
    InfoCard,
    TicketsTable,
  },
  data(){
    return {
      tickets: undefined,
      amount: '???',
      messages: '???'
    }
  },
  methods: {
    loadData(){
      fetch(this.$t('host') + '/ticket', {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Authorization': localStorage.accesstoken,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      }).then(res => {
        return res.json();
      })
          .then(res => {
            console.log(res)
            this.tickets = res.tickets
            this.amount = res.amount
            this.messages = res.messages
          }).catch((e) => {
        console.log(e)
      })
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>

<style scoped>

</style>