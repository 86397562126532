<template>
<div :style="'background-color:' + color" class="card">
  <div class="icon">
    <i :class="['fa', icon]"></i>
  </div>
  <div class="text">
    <h3>{{title}}</h3>
    <p v-html="strip(content)"></p>
  </div>
</div>

</template>

<script>
export default {
  name: "InfoCard",
  props:{
    icon: {type: String, default: 'fa-key'},
    title: {type: String},
    content: {type: String},
    color: {type: String, default: 'green'},
    html: {type: String, default: ''},
  },
  methods:{
    strip(text){
      const SCRIPT_REGEX = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
      while (SCRIPT_REGEX.test(text)) {
        text = text.replace(SCRIPT_REGEX, "");
      }
      return text;
    },
  },
  computed: {
    cssProps() {
      return {
        '--cardColor': this.color
      }
    }
  }
}
</script>

<style scoped>

:root{
}

.card{
  width: 100%;
  border-radius: 0.15rem;
  display: flex;
  flex-direction: row;
  justify-content: start;
}
.icon{
  height: 100%;
  background-color: rgba(0,0,0,0.15);
  border-bottom-left-radius: 0.15rem;
  border-top-left-radius: 0.15rem;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  padding: 1.5rem;
}

.text{
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

h3{
  font-size: 0.75rem;
  color: #bfbcbc;
}

p{
  font-size: 1.2rem;
  font-weight: bold;
  word-wrap: anywhere;
}

</style>