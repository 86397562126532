<template>
  <div class="home">

    <div class="smallcards">
      <InfoCard :title="$t('home.amount.licenses')" :content="licenses" icon="fa-unlock-alt" color="#E75A7C"></InfoCard>
      <InfoCard :title="$t('home.amount.tickets')" :content="tickets" icon="fa-book" color="#5E4AE3"></InfoCard>
      <InfoCard :title="$t('home.amount.warns')" :content="warns" icon="fa-exclamation-triangle"
                color="#FF331F"></InfoCard>
    </div>

    <ValidationGraph/>

    <WarnTable :user="userid"/>
  </div>

</template>

<script>
// @ is an alias to /src
import InfoCard from "@/components/ui/InfoCard";
import ValidationGraph from "@/components/home/ValidationGraph";
import WarnTable from "@/components/home/Punishments";

export default {
  name: 'Home',
  components: {
    InfoCard,
    ValidationGraph,
    WarnTable,
  },
  data() {
    return {
      licenses: '???',
      tickets: '???',
      warns: '???',
      userid: '-1',
    }
  },
  mounted() {

    if (localStorage.userdata) {
      let user = JSON.parse(localStorage.userdata)
      this.userid = user.id;
    }

    fetch(this.$t('host') + '/info/user', {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Authorization': localStorage.accesstoken,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(res => {
      // if(res.status == 403){
      //   localStorage.removeItem("accesstoken")
      //   localStorage.removeItem("userdata")
      //   window.location.href = '/login'
      // }
      console.log(res)
      return res.json();
    })
        .then(res => {
          console.log(res)
          this.licenses = res.licensesAmount
          this.tickets = res.ticketsAmount
          this.warns = res.warnsAmount
        }).catch((e) => {
      console.log(e)
      //localStorage.removeItem("accesstoken")
      // localStorage.removeItem("userdata")
    })
  }
}
</script>


<style>
.smallcards {
  display: flex;
  gap: 10px;
  margin-bottom: 3rem;
}
</style>
