<template>
  <Sidebar :username="username" class="sidebar"/>
  <div class="content">
    <router-view/>

  </div>
  <!-- TODO footer -->
</template>


<script>
import Sidebar from "@/components/ui/Sidebar";

export default {
  name: 'App',
  components: {
    Sidebar
  },
  data() {
    return {
      username: '',
    }
  },
  mounted() {
    if (localStorage.accesstoken && !localStorage.userdata) {
      this.fetchUserData();
    }
    window.addEventListener('key-set', () => {
      this.fetchUserData()
      setTimeout(() => window.location.href = "/", 500)
    });
    this.updateData()
  },
  methods: {
    fetchUserData() {
      fetch(
          this.$t('host') + '/oauth2/user',
          {
            method: 'GET',
            headers: {
              'Authorization': localStorage.accesstoken,
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
          }
      )
          .then(res => {
                console.log("Status: " + res.status)
                if (res.status === 200) {
                  return res.json();
                } else {
                  console.log(res.text())
                }
              }
          )
          .then(res => {
                console.log("jsondata: " + res)
                if (res.id) {
                  localStorage.userdata = JSON.stringify(res);
                  this.updateData();
                } else {
                  localStorage.removeItem("accesstoken")
                  localStorage.removeItem("userdata")
                }
              }
          )
          .catch((e) => {
                console.log(e)
                localStorage.removeItem("accesstoken")
                localStorage.removeItem("userdata")
              }
          )
    },
    updateData() {
      if (localStorage.userdata) {
        let user = JSON.parse(localStorage.userdata)
        this.username = user.username + '#' + user.discriminator;
        let locale = String(user.locale)
        if (locale.startsWith("de")) {
          this.$root.$i18n.locale = 'de'
        } else {
          this.$root.$i18n.locale = 'en'
        }
      }
    }
  }
}
</script>

<style>
:root {
  --red: #FF4A57;
  --bg1: #23263a;
  --bg2: #1F2235;
  --bgCon: #1F2937;
}

.accent {
  color: var(--red)
}


.sidebar {
  z-index: 9;
  position: fixed;
  top: 0;
}

body {
  color: white;
  background-color: #23292E;
  font-family: "Sofia", sans-serif;
}

.content {
  margin-left: 19rem;
  padding: 2rem;
}


.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 2px solid var(--red);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.3rem;
  color: white;
  background-color: transparent;
  box-shadow: black;
  transition: 500ms;
}

.btn:hover {
  background-color: var(--red);
}

.btn.green {
  border: 2px solid #59CD90;
}

.btn.green:hover {
  background-color: #59CD90;
}

.btn.gray {
  border: 2px solid gray;
}

.btn.gray:hover {
  background-color: gray;
}

.card {
  background-color: var(--bgCon);
  width: 100%;
  margin-bottom: 20px;
}

.card .title {
  padding: 1rem;
  font-size: 1rem;
  color: #bfbcbc;
}

.card .card-content {
  padding: 1rem;
}


.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}


.unloaded {
  content: '';
  background: linear-gradient(270deg, rgba(75, 85, 99), rgba(75, 85, 99), rgba(75, 85, 99), rgb(123, 137, 156), rgba(75, 85, 99), rgba(75, 85, 99), rgba(75, 85, 99));
  background-size: 600% 600%;
  animation: MoveBackground 2s ease-in-out infinite;
  @apply w-3/4 visible rounded p-2
}

@keyframes MoveBackground {
  0% {
    background-position: 100% 100%
  }
  100% {
    background-position: 0% 100%
  }
}

</style>
