export default {
  "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://api.primeapi.de"])},
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My licenses"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Tickets"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "customermanagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Management"])},
    "licenseManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License Management"])},
    "plugins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updates Management"])},
    "downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloads"])}
  },
  "home": {
    "amount": {
      "licenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your licenses"])},
      "tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your tickets"])},
      "warns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your warns"])}
    },
    "chart": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validations issued by your licenses"])}
    },
    "warns": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punishments"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revokes on"])},
      "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff"])}
    },
    "accounts": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linked accounts"])}
    }
  },
  "licenses": {
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your licenses"])},
    "pings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your license-pings"])},
    "table": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licenses"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claim license"])},
      "plugin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plugin"])},
      "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["license"])},
      "uses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pings (7 days)"])},
      "expire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["expires at"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "add": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add your License"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can add a license you received by a shop to link it to your Discord account"])},
      "abort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License"])},
      "alreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The license already was claimed! Contact support."])},
      "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The license could not be verified! Contact support."])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You successfully claimed the license!"])}
    }
  },
  "tickets": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Tickets"])},
    "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Ticket-Messages"])},
    "table": {
      "head": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
        "topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topic"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Ticket"])}
      },
      "noCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unknown"])},
      "stillOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currently open"])},
      "viewTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View ticket"])}
    },
    "view": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ticket-", _interpolate(_list(0))])},
      "unauthorised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have access to this ticket!"])},
      "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket not found!"])}
    }
  },
  "alert": {
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])}
  },
  "util": {
    "clipboardcopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied"])},
    "license": {
      "infinite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infinite"])},
      "locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locked"])}
    }
  },
  "downloads": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plugin Downloads"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plugin name"])},
    "latest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Version"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "downloadButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])}
  },
  "login": {
    "rules": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discord-Rules"])},
      "rule1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laws"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make sure to follow any Laws you may be restricted by."])}
      },
      "rule2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spamming"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To insure that we can have nice conversations, please to not spam. Spamming (e.g. sending unnecessary Messages or the same messages repeatedly) may result in a mute!"])}
      },
      "rule3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To be able to offer a high quality of support, please use the Tickets for individual support requests. For general questions you may use the public channels. Be sure to not send any personal data (e.g. Logs etc.) in those channels! Senden private information will result in a mute for your own safety"])}
      },
      "rule4": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagging"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagging staff members is highly forbidden. You will not receive any faster support by tagging the team. Repeatedly tagging will result in an automated mute! Be sure to deactivate the ping while replying. Also be sure to not ping any other member unnecessary."])}
      },
      "rule5": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discord TOS"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As this service is provided via Discord, you will have to follow the terms of services situated by discord. If we find you breaking those, we will have to take action."])}
      },
      "rule6": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direct messages"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please do not contact staff via DM. For any Questions regarding personal problems or questions with our product use the tickets"])}
      },
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept & Login"])}
    }
  },
  "settings": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])}
  }
}