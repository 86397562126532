export default {
  "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://api.primeapi.de"])},
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Lizenzen"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Tickets"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
    "customermanagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden Management"])},
    "licenseManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lizenzen Management"])},
    "plugins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updates Management"])},
    "downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloads"])}
  },
  "home": {
    "amount": {
      "licenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Lizenzen"])},
      "tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Tickets"])},
      "warns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine warns"])}
    },
    "chart": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lizenz-Validationen"])}
    },
    "warns": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestrafungen"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
      "timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Läuft ab am"])},
      "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teammitglied"])}
    },
    "accounts": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbundene accounts"])}
    }
  },
  "licenses": {
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Lizenzen"])},
    "pings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Lizenz-Validierungen"])},
    "table": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lizenzen"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lizenz verknüpfen (YMCS)"])},
      "plugin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plugin"])},
      "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lizenz"])},
      "uses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validierungen (7 Tage)"])},
      "expire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Läuft aus am"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "add": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lizenz beanspruchen"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn du eine Lizenz von YourMcShop oder Chunkfactory erhalen hast, kannst du diese hier mit deinem account verbinden!"])},
      "abort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lizenz"])},
      "alreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Lizenz ist bereits verknüpft. Wende dich an den Support!"])},
      "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die angegebene Lizenz existiert nicht. Wende dich an den Support."])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast die Lizenz erfolgreich beansprucht!!"])}
    }
  },
  "tickets": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Tickets"])},
    "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Ticket-Nachrichten"])},
    "table": {
      "head": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
        "topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thema"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlauf"])}
      },
      "noCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unbekannt"])},
      "stillOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aktuell offen!"])},
      "viewTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlauf anzeigen"])}
    },
    "view": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ticket-", _interpolate(_list(0))])},
      "unauthorised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast kein Zugriff auf dieses Ticket!"])},
      "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket nicht gefunden!"])}
    }
  },
  "alert": {
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolg"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])}
  },
  "util": {
    "clipboardcopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiert"])},
    "license": {
      "infinite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unendlich"])},
      "locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesperrt"])}
    }
  },
  "downloads": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plugin Downloads"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plugin name"])},
    "latest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Version"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "downloadButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])}
  },
  "login": {
    "rules": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discord Regeln"])},
      "rule1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesetze"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halte dich an alle geltenden Gesetze."])}
      },
      "rule2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spamming"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um eine angenehme Atmosphäre zum chatten zu schaffen, bitten wir dich nicht zu spammen. Schnelles schreiben von unnötigen Nachrichten oder mehrmaliges Wiederholen von Nachricht wird in ein Mute resultieren."])}
      },
      "rule3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um einen möglichst hochqualitativen Support anbieten zu können, nutze bitte das TicketSystem auf dem Discord-Server. Für generelle Fragen können die öffentlichen Channel genutzt werden. Achte hier jedoch darauf, dass du keine persönlichen Informationen (wie bspw. logs) teilst. Senden von Logs kann zu deiner eigenen Sicherheit zu einem Mute führen."])}
      },
      "rule4": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pingen"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Pingen von Teammitgliedern ist verboten. Du wirst keinen schnelleren Support erhalten, wenn du uns pingst. Wiederholtes makieren wird zu einem (automatischem) mute führen! Achte ebenfalls darauf andere User nicht unnötig zu pingen."])}
      },
      "rule5": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discord TOS"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da der Support über Discord angeboten wird, musst du die an die TOS von Discord halten. Sollten du gegen diese verstoßen müssen wir dagegen vorgehen."])}
      },
      "rule6": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direktnachrichten (DM)"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wende dich für support nicht per Direktnachricht an die Teammitglieder. Nutze dafür die Ticketfunktion."])}
      },
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept & Login"])}
    }
  }
}