<template>
  <div class="smallcards">
    <InfoCard title="Discord-Member" :content="memberCount" icon="fa-users" color="#E75A7C"></InfoCard>
    <InfoCard title="Registrierte Member" :content="clientCount" icon="fa-users" color="#5E4AE3"></InfoCard>
    <InfoCard title="Verwarnungen" :content="warnCount" icon="fa-exclamation-triangle" color="#FF331F"></InfoCard>
    <InfoCard title="Tickets" :content="ticketCount" icon="fa-ticket" color="#ECFF5F"></InfoCard>
    <InfoCard title="Lizenzen" :content="licenseCount" icon="fa-key" color="#44F4FF"></InfoCard>
    <InfoCard title="Validierungen" :content="pingTotalCount" icon="fa-link" color="#DB8129"></InfoCard>
    <InfoCard title="Validierungen (7d)" :content="ping7dlCount" icon="fa-link" color="#590422"></InfoCard>
  </div>
  <ClientOverview />
</template>

<script>

import InfoCard from "@/components/ui/InfoCard";
import ClientOverview from "@/components/admin/ClientOverview";

export default {
  name: "Kundenmanagement",
  components: {
    InfoCard,
    ClientOverview
  },
  data(){
    return{
      memberCount: '???',
      clientCount: '???',
      warnCount: '???',
      ticketCount: '???',
      licenseCount: '???',
      pingTotalCount: '???',
      ping7dlCount: '???',
    }
  },
  methods: {
    loadStats() {
      fetch(
          this.$t('host') + '/admin/stats',
          {
            method: 'GET',
            mode: 'cors',
            headers: {
              'Authorization': localStorage.accesstoken,
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
          }
      ).then(
          res => {
            return res.json();
          }
      )
          .then(
              res => {
                this.memberCount = res.memberCount
                this.clientCount = res.clientCount
                this.warnCount = res.warnCount
                this.ticketCount = res.ticketCount
                this.licenseCount = res.licenseCount
                this.pingTotalCount = res.pingTotalCount
                this.ping7dlCount = res.ping7dlCount
              }
          )
          .catch(
              (e) => {
                console.log(e)
              }
          )
    },
  },
  mounted() {
    this.loadStats()
  },
}
</script>

<style scoped>

.smallcards {
  display: flex;
  gap: 10px;
  margin-bottom: 3rem;
  flex-wrap: wrap;
  flex-direction: row;
}

.smallcards *{
  width: 24%;
}

.cardrow {
  display: flex;
  gap: 1.5rem;
}
</style>