<template>
<div class="plugin">
  <h1 class="p-4">SpigotAPI</h1>
  <hr>
  <div class="mt-4">
    <div class="check failed">
      <div class="icon"><i class="fa fa-times" aria-hidden="true"></i></div>
      <div class="title">MySQL</div>
      <div class="desc">You failed to create a mysql connection u dump bitch</div>
      <a href="https://wiki.primeapi.de" class="info"><i class="fa fa-question-circle" aria-hidden="true"></i></a>
    </div>
    <div class="check passed">
      <div class="icon"><i class="fa fa-check" aria-hidden="true"></i></div>
      <div class="title">MySQL</div>
      <div class="desc">You failed to create a mysql connection u dump bitch</div>
      <a href="https://wiki.primeapi.de" class="info"><i class="fa fa-question-circle" aria-hidden="true"></i></a>
    </div>
    <div class="check warn">
      <div class="icon"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></div>
      <div class="title">MySQL</div>
      <div class="desc">You failed to create a mysql connection u dump bitch</div>
      <a href="https://wiki.primeapi.de" class="info"><i class="fa fa-question-circle" aria-hidden="true"></i></a>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "Diagonsis"
}
</script>

<style scoped>
 .plugin{
   @apply bg-gray-800
 }

 .check {
   @apply flex w-full
   ;
 }

 .check .title{
   @apply transition p-4
 }
 .check .desc{
   @apply transition p-4
 }
 .check .info{
   @apply transition p-4
   ;
   margin-left: auto;
 }


 .check .icon{
   @apply p-4 bg-black bg-opacity-60 w-auto
   ;
   min-width: 3rem;
 }

 .check.passed{
   @apply bg-green-500;
 }
 .check.failed{
   @apply bg-red-500;
 }
 .check.warn{
   @apply bg-yellow-500;
 }
</style>