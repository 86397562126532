<template>

  <div class="card">
    <div class="title">{{ $t('home.warns.title') }}</div>
    <hr>
    <div class="card-content">
      <div class="flex flex-col">
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block py-2 min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden shadow-md sm:rounded-lg">

              <table class="min-w-full dark">
                <thead class=" dark:bg-gray-700">
                <tr>
                  <th class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase dark:text-gray-400"
                      scope="col">
                    {{ $t('home.warns.type') }}
                  </th>
                  <th scope="col"
                      class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase dark:text-gray-400">
                    {{ $t('home.warns.reason') }}
                  </th>
                  <th scope="col"
                      class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase dark:text-gray-400">
                    {{ $t('home.warns.timeout') }}
                  </th>
                </tr>
                </thead>
                <tbody>

                <tr
                    v-show="!this.punishments"
                    class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                    <div class="unloaded"></div>
                  </td>
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400 key ">
                    <div class="unloaded"></div>
                  </td>
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                    <div class="unloaded"></div>
                  </td>
                </tr>


                <tr v-for="punishment in this.punishments"
                    v-show="!punishment.revoked &&  (punishment.timeout == -1 || punishment.timeout > new Date().getTime())"
                    :key="punishment.id"
                    class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">

                  <td
                      class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400 key ">{{
                      punishment.type
                                                                                                        }}
                  </td>
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                    {{ punishment.reason }}
                  </td>
                  <td
                      class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400 key ">{{
                      renderTime(punishment.timeout)
                                                                                                        }}
                  </td>
                  <!--                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">-->
                  <!--                    <div class="userdispay">-->
                  <!--                      <img :src="warn.staffURL" alt="">-->
                  <!--                      <span>{{ warn.staffName }}</span>-->
                  <!--                    </div>-->
                  <!--                  </td>-->
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
export default {
  name: "WarnTable",
  props: {
    user: {type: Object}
  },
  data() {
    return{
      punishments: undefined
    }
  },
  mounted() {
    this.fetchData()
  },
  watch: {
    user: function(newVal){
      if(newVal != '1'){
        this.fetchData()
      }
    }
  },
  methods: {
    renderTime(timestamp) {
      console.log(timestamp)
      if (timestamp == -1) {
        return this.$t('util.license.infinite')
      } else if (timestamp == 0) {
        return this.$t('util.license.locked')
      } else {
        return new Date(timestamp).toLocaleDateString('de-DE') + ' ' + new Date(timestamp).toLocaleTimeString('de-DE')
      }
    },
    fetchData() {
      if (!this.user || this.user == '') return
      fetch(
          this.$t('host') + '/info/user/' + this.user + '/punishments',
          {
            method: 'GET',
            headers: {
              'Authorization': localStorage.accesstoken,
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
          }
      )
          .then(res => {
                if (res.status === 200) {
                  return res.json();
                } else {
                  console.log(res.text())
                }
              }
          )
          .then(res => {
                console.log("jsondata: " + res)
                this.punishments = res;
                console.log(new Date().getTime())
              }
          )
          .catch((e) => {
                console.log(e)
              }
          )
    },
  }

}
</script>

<style scoped>

.userdispay{
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0.75em;
  transition: 500ms;
}

.userdispay img{
  height: 2em;
  width: 2em;
  border-radius: 100%;
}
.userdispay span {
  transition: 500ms;
}
.userdispay span:hover {
  color: var(--red);
}


</style>