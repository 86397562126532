<template>

  <div class="card">
    <div class="title">{{ $t('downloads.title') }}</div>
    <hr>
    <div class="card-content">
      <div class="flex flex-col">
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block py-2 min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden shadow-md sm:rounded-lg">

              <table class="min-w-full dark">
                <thead class=" dark:bg-gray-700">
                <tr>
                  <th class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase dark:text-gray-400"
                      scope="col">
                    {{ $t('downloads.name') }}
                  </th>
                  <th class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase dark:text-gray-400"
                      scope="col">
                    {{ $t('downloads.latest') }}
                  </th>
                  <th class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase dark:text-gray-400"
                      scope="col">
                    {{ $t('downloads.download') }}
                  </th>
                </tr>
                </thead>
                <tbody>

                <tr
                    v-show="!plugins"
                    class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                    <div class="unloaded"></div>
                  </td>
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400 key ">
                    <div class="unloaded"></div>
                  </td>
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                    <div class="unloaded"></div>
                  </td>
                </tr>

                <tr v-for="plugin in plugins" v-show="plugin.authorized" :key="plugin.id"
                    class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <td
                      class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400 key ">{{
                      plugin.displayname
                                                                                                        }}
                  </td>
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">{{
                      plugin.latest
                    }}
                  </td>
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                    <button class="btn green" @click="download(plugin)"><i class="fa fa-download"></i>
                      {{ $t('downloads.downloadButton') }}
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>


export default {
  name: "PluginManagement",
  components: {},
  data() {
    return {
      plugins: undefined
    }
  },
  methods: {
    loadData() {
      fetch(
          this.$t('host') + '/plugins/',
          {
            method: 'GET',
            headers: {
              'Authorization': localStorage.accesstoken,
              'Access-Control-Allow-Origin': '*',
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
      )
          .then(res => {
                if (res.status == 200) {
                  return res.json();
                } else {
                  console.log(res.text())
                }
              }
          )
          .then(res => {
                this.plugins = res
              }
          )
          .catch((e) => {
                console.log(e)
              }
          )
    },
    download(plugin) {
      window.location.href = this.$t('host') + '/files/' + plugin.name + "/" + plugin.latest + "?key=" + localStorage.accesstoken;
    }
  },
  mounted() {
    this.loadData()
    if (this.$route.query.download && this.$route.query.version) {
      window.location.href = this.$t('host') + '/files/' + this.$route.query.download + "/" + this.$route.query.version + "?key=" + localStorage.accesstoken;
    }
  }
}
</script>

<style scoped>
</style>