<template>
  <div class="card">
    <div class="title">{{ $t('home.chart.title') }}</div>
    <hr>
    <div class="card-content">
      <div id="graph"></div>
    </div>
  </div>
</template>

<script>

import ApexCharts from 'apexcharts'


export default {
  name: "ValidationGraph",
  data() {
    return {
      chartData: [],
    }
  },
  methods: {
    async drawChrat() {
      var options = {
        colors: ['#FF4A57', '#E91E63', '#9C27B0'],
        series: [{
          name: 'Validierungen',
          data: this.chartData,
        }],
        chart: {
          type: 'area',
          stacked: false,
          height: 350,
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
          },
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100]
          },
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return val;
            },
          },
          title: {
            text: 'Validierungen'
          },
        },
        xaxis: {
          type: 'datetime',
        },
        tooltip: {
          shared: false,
          theme: 'dark',
          y: {
            formatter: function (val) {
              return val
            }
          },
          x: {
            show: true,
            format: 'dd MMM HH:00',
          }
        },
        theme: {},
        background: 'rgba(0, 0, 0, 0)'
      };

      const chart = new ApexCharts(document.querySelector("#graph"), options);
      await chart.render();

      document.querySelector("svg").style.background = "transparent"
    },
    async loadData() {
      console.log("loading data....")
      await fetch(this.$t('host') + '/info/stats', {
        method: 'GET',
        headers: {
          'Authorization': localStorage.accesstoken,
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }).then(res => {
        console.log(res)
        return res.json();
      })
          .then(res => {
            this.chartData = res;
            this.drawChrat()
          }).catch((e) => {
        console.log(e)
      })
    }
  },
  mounted() {
    this.loadData();
  }
}
</script>

<style scoped>
#graph.apexcharts-canvas.apexcharts-theme-dark {
  background: transparent !important;
}

</style>