<template>

  <div class="smallcards">
    <InfoCard :title="$t('licenses.amount')" :content="licensesAmount" icon="fa-lock"
              color="#E75A7C"></InfoCard>
    <InfoCard :title="$t('licenses.pings')" :content="licensensPings" icon="fa-book"
              color="#5E4AE3"></InfoCard>
  </div>

  <LicensesTable @updateData="loadData" :licenses="licenses" />
</template>

<script>

import InfoCard from "@/components/ui/InfoCard";
import LicensesTable from "@/components/licenses/LicensesTable";

export default {
  name: "Licenses",
  components: {
    InfoCard,
    LicensesTable
  },
  data() {
    return {
      licensesAmount: '???',
      licensensPings: '???',
      licenses: undefined,
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData(){
      fetch(this.$t('host') + '/info/user/license', {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Authorization': localStorage.accesstoken,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      }).then(res => {
        console.log(res)
        return res.json();
      })
          .then(res => {
            console.log(res)
            this.licensesAmount = res.amount
            this.licensensPings = res.licensePings
            this.licenses = res.licenses
          }).catch((e) => {
        console.log(e)
        //localStorage.removeItem("accesstoken")
        // localStorage.removeItem("userdata")
      })
    }
  }
}
</script>

<style scoped>

</style>