<template>

  <div class="card">
    <div class="title">{{ $t("login.rules.title") }}</div>
    <hr>
    <div class="card-content">
      <div class="container px-6 mx-auto dark">

        <div class="grid grid-cols-1 gap-8 mt-8 lg:mt-16 md:grid-cols-2 xl:grid-cols-3">
          <div>
            <div class="inline-block p-3 text-white bg-blue-600 rounded-lg text-center">
              <i class="fa fa-book w-6 h-6 text-xl"></i>
            </div>
            <div>
              <h1 class="text-xl font-semibold text-gray-700 dark:text-white">{{ $t('login.rules.rule1.title') }}</h1>
              <p class="mt-2 text-sm text-gray-500 dark:text-gray-300">
                {{ $t('login.rules.rule1.desc') }}
              </p>
            </div>
          </div>
          <div>
            <div class="inline-block p-3 text-white bg-blue-600 rounded-lg text-center">
              <i class="fa fa-repeat w-6 h-6 text-xl"></i>
            </div>
            <div>
              <h1 class="text-xl font-semibold text-gray-700 dark:text-white">{{ $t('login.rules.rule2.title') }}</h1>
              <p class="mt-2 text-sm text-gray-500 dark:text-gray-300">
                {{ $t('login.rules.rule2.desc') }}
              </p>
            </div>
          </div>
          <div>
            <div class="inline-block p-3 text-white bg-blue-600 rounded-lg text-center">
              <i class="fa fa-ticket w-6 h-6 text-xl"></i>
            </div>
            <div>
              <h1 class="text-xl font-semibold text-gray-700 dark:text-white">{{ $t('login.rules.rule3.title') }}</h1>
              <p class="mt-2 text-sm text-gray-500 dark:text-gray-300">
                {{ $t('login.rules.rule3.desc') }}
              </p>
            </div>
          </div>
          <div>
            <div class="inline-block p-3 text-white bg-blue-600 rounded-lg text-center">
              <i class="fa fa-map-pin w-6 h-6 text-xl"></i>
            </div>
            <div>
              <h1 class="text-xl font-semibold text-gray-700 dark:text-white">{{ $t('login.rules.rule4.title') }}</h1>
              <p class="mt-2 text-sm text-gray-500 dark:text-gray-300">
                {{ $t('login.rules.rule4.desc') }}
              </p>
            </div>
          </div>
          <div>
            <div class="inline-block p-3 text-white bg-blue-600 rounded-lg text-center">
              <i class="fa fa-list w-6 h-6 text-xl"></i>
            </div>
            <div>
              <h1 class="text-xl font-semibold text-gray-700 dark:text-white">{{ $t('login.rules.rule5.title') }}</h1>
              <p class="mt-2 text-sm text-gray-500 dark:text-gray-300">
                {{ $t('login.rules.rule5.desc') }}
              </p>
            </div>
          </div>
          <div>
            <div class="inline-block p-3 text-white bg-blue-600 rounded-lg text-center">
              <i class="fa fa-comment w-6 h-6 text-xl"></i>
            </div>
            <div>
              <h1 class="text-xl font-semibold text-gray-700 dark:text-white">{{ $t('login.rules.rule6.title') }}</h1>
              <p class="mt-2 text-sm text-gray-500 dark:text-gray-300">
                {{ $t('login.rules.rule6.desc') }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full text-right">
        <button class="btn green" @click="redirect()">{{ $t('login.rules.button') }}</button>
      </div>

    </div>
  </div>

  <!--
    <div class="card">
      <div class="title">Produkte</div>
      <hr>
      <div class="card-content dark">
        <div class="container px-6 mx-auto">
          <div class="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-16 md:grid-cols-2 xl:grid-cols-3">
            <div class="space-y-3">
                          <span class="inline-block p-3 text-blue-500 bg-blue-100 rounded-full dark:text-white dark:bg-blue-500">
                              <svg xmlns="http://www.w3.org/2000/svg"
                                   class="w-6 h-6"
                                   fill="none"
                                   viewBox="0 0 24 24"
                                   stroke="currentColor">
                                  <path stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"/>
                              </svg>
                          </span>

              <h1 class="text-2xl font-semibold text-gray-700 capitalize dark:text-white">Ban & BungeeSystem (inkl. Webinterface)</h1>

              <p class="text-gray-500 dark:text-gray-300">
                Einmal BungeeSystem mit alles!
                Dieses System beinhaltet ein BanSystem, Mute-, Warn-, Kick-System, Verify für Discord und Teamspeak, Freundes-,Clan- und Party-System und vieles mehr. Und dazu: Ein Webinterface mit vielen vielen Funktionen! Perfekt für deinen Server.
              </p>

              <a href="#"
                 class="inline-flex items-center -mx-1 text-sm text-blue-500 capitalize transition-colors duration-200 transform dark:text-blue-400 hover:underline hover:text-blue-600 dark:hover:text-blue-500">
                <span class="mx-1">Jetzt ansehen</span>
                <svg class="w-4 h-4 mx-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"></path>
                </svg>
              </a>
            </div>

            <div class="space-y-3">
                          <span class="inline-block p-3 text-blue-500 bg-blue-100 rounded-full dark:text-white dark:bg-blue-500">
                              <svg xmlns="http://www.w3.org/2000/svg"
                                   class="w-6 h-6"
                                   fill="none"
                                   viewBox="0 0 24 24"
                                   stroke="currentColor">
                                  <path stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"/>
                              </svg>
                          </span>

              <h1 class="text-2xl font-semibold text-gray-700 capitalize dark:text-white">Zero Configrations</h1>

              <p class="text-gray-500 dark:text-gray-300">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident ab nulla quod dignissimos vel non
                corrupti doloribus voluptatum eveniet
              </p>

              <a href="#"
                 class="inline-flex items-center -mx-1 text-sm text-blue-500 capitalize transition-colors duration-200 transform dark:text-blue-400 hover:underline hover:text-blue-600 dark:hover:text-blue-500">
                <span class="mx-1">read more</span>
                <svg class="w-4 h-4 mx-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"></path>
                </svg>
              </a>
            </div>

            <div class="space-y-3">
                          <span class="inline-block p-3 text-blue-500 bg-blue-100 rounded-full dark:text-white dark:bg-blue-500">
                              <svg xmlns="http://www.w3.org/2000/svg"
                                   class="w-6 h-6"
                                   fill="none"
                                   viewBox="0 0 24 24"
                                   stroke="currentColor">
                                  <path stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"/>
                              </svg>
                          </span>

              <h1 class="text-2xl font-semibold text-gray-700 capitalize dark:text-white">New Components Every month</h1>

              <p class="text-gray-500 dark:text-gray-300">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident ab nulla quod dignissimos vel non
                corrupti doloribus voluptatum eveniet
              </p>

              <a href="#"
                 class="inline-flex items-center -mx-1 text-sm text-blue-500 capitalize transition-colors duration-200 transform dark:text-blue-400 hover:underline hover:text-blue-600 dark:hover:text-blue-500">
                <span class="mx-1">read more</span>
                <svg class="w-4 h-4 mx-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"></path>
                </svg>
              </a>
            </div>

            <div class="space-y-3">
                          <span class="inline-block p-3 text-blue-500 bg-blue-100 rounded-full dark:text-white dark:bg-blue-500">
                              <svg xmlns="http://www.w3.org/2000/svg"
                                   class="w-6 h-6"
                                   fill="none"
                                   viewBox="0 0 24 24"
                                   stroke="currentColor">
                                  <path stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z"/>
                                  <path stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z"/>
                              </svg>
                          </span>

              <h1 class="text-2xl font-semibold text-gray-700 capitalize dark:text-white">elegant Dark Mode</h1>

              <p class="text-gray-500 dark:text-gray-300">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident ab nulla quod dignissimos vel non
                corrupti doloribus voluptatum eveniet
              </p>

              <a href="#"
                 class="inline-flex items-center -mx-1 text-sm text-blue-500 capitalize transition-colors duration-200 transform dark:text-blue-400 hover:underline hover:text-blue-600 dark:hover:text-blue-500">
                <span class="mx-1">read more</span>
                <svg class="w-4 h-4 mx-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"></path>
                </svg>
              </a>
            </div>

            <div class="space-y-3">
                          <span class="inline-block p-3 text-blue-500 bg-blue-100 rounded-full dark:text-white dark:bg-blue-500">
                              <svg xmlns="http://www.w3.org/2000/svg"
                                   class="w-6 h-6"
                                   fill="none"
                                   viewBox="0 0 24 24"
                                   stroke="currentColor">
                                  <path stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z"/>
                              </svg>
                          </span>

              <h1 class="text-2xl font-semibold text-gray-700 capitalize dark:text-white">Easy to customiztions</h1>

              <p class="text-gray-500 dark:text-gray-300">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident ab nulla quod dignissimos vel non
                corrupti doloribus voluptatum eveniet
              </p>

              <a href="#"
                 class="inline-flex items-center -mx-1 text-sm text-blue-500 capitalize transition-colors duration-200 transform dark:text-blue-400 hover:underline hover:text-blue-600 dark:hover:text-blue-500">
                <span class="mx-1">read more</span>
                <svg class="w-4 h-4 mx-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"></path>
                </svg>
              </a>
            </div>

            <div class="space-y-3">
                          <span class="inline-block p-3 text-blue-500 bg-blue-100 rounded-full dark:text-white dark:bg-blue-500">
                              <svg xmlns="http://www.w3.org/2000/svg"
                                   class="w-6 h-6"
                                   fill="none"
                                   viewBox="0 0 24 24"
                                   stroke="currentColor">
                                  <path stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"/>
                              </svg>
                          </span>

              <h1 class="text-2xl font-semibold text-gray-700 capitalize dark:text-white">Simple & clean designs</h1>

              <p class="text-gray-500 dark:text-gray-300">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident ab nulla quod dignissimos vel non
                corrupti doloribus voluptatum eveniet
              </p>

              <a href="#"
                 class="inline-flex items-center -mx-1 text-sm text-blue-500 capitalize transition-colors duration-200 transform dark:text-blue-400 hover:underline hover:text-blue-600 dark:hover:text-blue-500">
                <span class="mx-1">read more</span>
                <svg class="w-4 h-4 mx-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>

      </div>
    </div>
    -->

</template>

<script>
export default {
  name: "Login",
  mounted() {
    if (this.$route.query.code) {
      this.verifyCode(this.$route.query.code)
    }
  },
  methods: {
    redirect() {
      let query = {
        client_id: "797414153487777803",
        // redirect_uri: window.location.href,
        redirect_uri: "https://dash.primeapi.de/login",
        response_type: 'code',
        scope: 'identify guilds guilds.join'
      }
      window.location.href = 'http://discordapp.com/api/oauth2/authorize' + '?' + new URLSearchParams(query).toString()
    },
    async verifyCode(code) {
      fetch(this.$t('host') + '/oauth2/code?code=' + code, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      }).then(res => res.json())
          .then(res => {
            if (res.access_token) {
              localStorage.accesstoken = res.access_token;
              window.dispatchEvent(new CustomEvent('key-set'));
            }
          })
    }
  }
}
</script>

<style scoped>

</style>