import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'
import Licenses from "@/views/Licenses";
import Login from "@/views/Login";
import Tickets from "@/views/Tickets";
import Logout from "@/views/Logout";
import Kundenmanagement from "@/views/Kundenmanagement";
import Ticket from "@/views/Ticket";
import User from "@/views/User";
import License from "@/views/License";
import LicenseManagement from "@/views/LicenseManagement";
import PluginManagement from "@/views/PluginManagement";
import Downloads from "@/views/Downloads";
import Paste from "@/views/Paste";
import PasteView from "@/views/PasteView";
import Imprint from "@/views/Imprint";
import Settings from "@/views/Settings";
import Diagonsis from "@/views/Diagonsis";

const routes = [
  {
    path: '/',
    name: 'Home',
    displayPath: 'nav.home',
    component: Home,
    icon: "fa fa-home",
    protected: false,
    splitBevore: false,
    list: true
  },
  {
    path: '/licenses',
    name: 'Lizenzen',
    component: Licenses,
    displayPath: 'nav.license',
    icon: "fa fa-key",
    protected: false,
    splitBevore: false,
    list: true
  },
  {
    path: '/downloads',
    name: 'Downloads',
    component: Downloads,
    displayPath: 'nav.downloads',
    icon: "fa fa-download",
    protected: false,
    splitBevore: false,
    list: true
  },
  {
    path: '/tickets',
    name: 'Tickets',
    component: Tickets,
    displayPath: 'nav.tickets',
    icon: "fa fa-ticket",
    protected: false,
    splitBevore: false,
    list: true
  },
  {
    path: '/ticket/:id',
    name: 'Ticket',
    component: Ticket,
    displayPath: 'nav.tickets',
    icon: "fa fa-ticket",
    protected: false,
    splitBevore: false,
    list: false
  },
  {
    path: '/users',
    name: 'Kundenmanegement',
    component: Kundenmanagement,
    displayPath: 'nav.customermanagement',
    icon: "fa fa-users",
    protected: true,
    splitBevore: true,
    list: true
  },
  {
    path: '/user/:id',
    name: 'User',
    component: User,
    displayPath: 'nav.customermanagement',
    icon: "fa fa-users",
    protected: true,
    splitBevore: false,
    list: false
  },
  {
    path: '/license/:key',
    name: 'License',
    component: License,
    displayPath: 'nav.customermanagement',
    icon: "fa fa-users",
    protected: true,
    splitBevore: false,
    list: false
  },
  {
    path: '/paste/:id',
    name: 'PasteView',
    component: PasteView,
    displayPath: 'nav.customermanagement',
    icon: "fa fa-users",
    protected: false,
    splitBevore: false,
    list: false
  },
  {
    path: '/paste',
    name: 'Paste',
    component: Paste,
    displayPath: 'nav.customermanagement',
    icon: "fa fa-users",
    protected: false,
    splitBevore: false,
    list: false
  },
  {
    path: '/licensemanagement',
    name: 'LicenseManagement',
    component: LicenseManagement,
    displayPath: 'nav.licenseManagement',
    icon: "fa fa-gears",
    protected: true,
    splitBevore: false,
    list: true
  },
  {
    path: '/plugins',
    name: 'Plugins',
    component: PluginManagement,
    displayPath: 'nav.plugins',
    icon: "fa fa-code",
    protected: true,
    splitBevore: false,
    list: true
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    displayPath: 'nav.login',
    icon: null,
    protected: false,
    splitBevore: false,
    list: false
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    displayPath: 'nav.logout',
    icon: 'fa fa-sign-out',
    protected: false,
    splitBevore: true,
    list: true
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    displayPath: 'nav.settings',
    icon: 'fa fa-cog',
    protected: false,
    splitBevore: false,
    list: false
  },
  {
    path: '/diagnosis',
    name: 'Diagnose',
    component: Diagonsis,
    displayPath: 'nav.settings',
    icon: 'fa fa-cog',
    protected: false,
    splitBevore: false,
    list: false
  },
  {
    path: '/imprint',
    name: 'Impressum',
    component: Imprint,
    displayPath: 'nav.logout',
    icon: 'fa fa-sign-out',
    protected: false,
    splitBevore: true,
    list: false
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  if(localStorage.accesstoken == null && to.path !== "/login"){
    next('/login')
  }else{
    next();
  }
})

export default router

export const list = routes
