<template>

  <div class="card">
    <div class="title">Plugins</div>
    <hr>
    <div class="card-content">
      <div class="flex flex-col">
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block py-2 min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden shadow-md sm:rounded-lg">

              <table class="min-w-full dark">
                <thead class=" dark:bg-gray-700">
                <tr>
                  <th class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase dark:text-gray-400"
                      scope="col">
                    #
                  </th>
                  <th class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase dark:text-gray-400"
                      scope="col">
                    Name
                  </th>
                  <th class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase dark:text-gray-400"
                      scope="col">
                    Latest Version
                  </th>
                  <th class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase dark:text-gray-400"
                      scope="col">
                    Download
                  </th>
                  <th class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase dark:text-gray-400"
                      scope="col">
                    Update
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="plugin in plugins" :key="plugin.id"
                    class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                    {{ plugin.name + ' [' + plugin.id + ']' }}
                  </td>
                  <td
                      class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400 key ">{{
                      plugin.displayname
                    }}
                  </td>
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">{{
                      plugin.latest
                    }}
                  </td>
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                    <button class="btn" @click="download(plugin)"><i class="fa fa-download"></i> Download</button>
                  </td>
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                    <button class="btn green" @click="this.$refs.modal.show(plugin)"><i class="fa fa-pencil"></i> Update
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>

  <PluginEditModal ref="modal" @update="loadData"/>

  <button class="edit bg-green-600" @click="this.$refs.modal.openCreate()"><i class="fa fa-plus"></i></button>
</template>

<script>

import PluginEditModal from "@/components/admin/PluginEditModal";

export default {
  name: "PluginManagement",
  components: {
    PluginEditModal
  },
  data() {
    return {
      plugins: []
    }
  },
  methods: {
    loadData() {
      fetch(
          this.$t('host') + '/plugins/',
          {
            method: 'GET',
            headers: {
              'Authorization': localStorage.accesstoken,
              'Access-Control-Allow-Origin': '*',
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
      )
          .then(res => {
                if (res.status == 200) {
                  return res.json();
                } else {
                  console.log(res.text())
                }
              }
          )
          .then(res => {
                this.plugins = res
              }
          )
          .catch((e) => {
                console.log(e)
              }
          )
    },
    download(plugin) {
      window.location.href = this.$t('host') + '/files/' + plugin.name + "/" + plugin.latest + "?key=" + localStorage.accesstoken;
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>

<style scoped>
.edit {
  position: fixed;
  right: 2.7rem;
  bottom: 2.7rem;
  font-size: 1.5rem;
  border-radius: 5rem;
  transition: 250ms;
  width: 4rem;
  height: 4rem;
}

.edit:hover {
  transform: scale(1.1);
}
</style>