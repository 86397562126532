<template>
<h1></h1>
</template>

<script>
export default {
  name: "Logout",
  mounted() {
    localStorage.removeItem("accesstoken")
    localStorage.removeItem("userdata")
    window.dispatchEvent(new CustomEvent('key-set'));
  }
}
</script>

<style scoped>

</style>