<template>
  <div v-show="showModal" aria-labelledby="modal-title" aria-modal="true" class="fixed z-10 inset-0 overflow-y-auto "
       role="dialog">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 ">
      <div aria-hidden="true" class="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity"></div>

      <span aria-hidden="true" class="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>
      <div id="popup"
           class="inline-block align-bottom dark bg-dark rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div class=" px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div
                class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-gray-900 sm:mx-0 sm:h-10 sm:w-10">
              <i class="fa fa-pencil"></i>
            </div>
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">

              <h3 id="modal-title" class="text-lg leading-6 font-medium ">
                Plugin bearbeiten/erstellen
              </h3>
              <div class="mt-2">
                <div class="flex flex-col mt-8 space-y-3 sm:space-y-0 sm:flex-row sm:justify-start sm:-mx-2">
                  <form @submit="clicked" v-on:submit.prevent>
                    <div class="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">

                      <div v-show="this.newPlugin">
                        <label class="text-gray-700 dark:text-gray-200" for="pluginName">Plugin</label>
                        <select id="pluginName" v-model="plugin.name"
                                class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                        >
                          <option v-for="file in files" :key="file"> {{ file }}</option>
                        </select>
                      </div>

                      <div>
                        <label class="text-gray-700 dark:text-gray-200" for="pluginVersion">Version</label>
                        <select id="pluginVersion" v-model="plugin.latest"
                                class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"

                                @blur="loadVersions">
                          <option v-for="version in versions" :key="version"> {{ version }}</option>
                        </select>
                      </div>

                      <div>
                        <label class="text-gray-700 dark:text-gray-200" for="display">Displayname</label>
                        <input id="display" v-model="plugin.displayname"
                               class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                               type="text">

                      </div>

                      <div v-show="this.newPlugin">
                        <label class="text-gray-700 dark:text-gray-200" for="license">Benötigte Lizenz</label>
                        <input id="license" v-model="plugin.license"
                               class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                               type="text">
                      </div>
                    </div>

                    <br>
                    <div v-show="!this.newPlugin">
                      <div class="text-lg leading-6 font-medium flex justify-between">
                        <span>Update-Message</span>
                        <label class="switch">
                          <input id="updateSendMessage" type="checkbox" v-bind="sendMessage" @click="updateSendMessage">
                          <span class="slider"></span>
                        </label>
                      </div>
                      <div v-show="sendMessage">
                        <div id="featureList">
                          <h4>New Features</h4>
                          <div class="listItem flex gap-1.5">
                            <input id="featureInput"
                                   class="block w-3/4 px-4 py-1 mt-2 border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                                   @keyup="addUpdate">
                            <button class="text-green-600 hover:text-green-800" type="button" @click="addUpdate">
                              <i class="fa fa-check"></i></button>
                          </div>


                          <div v-for="(item, index) in updates" :key="item" class="listItem flex gap-1.5">
                            <input :value="item"
                                   class="block w-3/4 px-4 py-1 mt-2 border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                                   disabled>
                            <button class="text-red-600 hover:text-red-800" type="button" @click="removeUpdate(index)">
                              <i class="fa fa-times"></i></button>
                          </div>
                        </div>
                        <br>
                        <div id="bugList">
                          <h4>Bugfixes</h4>
                          <div class="listItem flex gap-1.5">
                            <input id="bugInput"
                                   class="block w-3/4 px-4 py-1 mt-2 border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                                   @keyup="addBug">
                            <button class="text-green-600 hover:text-green-800" type="button" @click="addBug">
                              <i class="fa fa-check"></i></button>
                          </div>
                          <div v-for="(item, index) in bugs" :key="item" class="listItem flex gap-1.5">
                            <input :value="item"
                                   class="block w-3/4 px-4 py-1 mt-2 border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                                   disabled>
                            <button class="text-red-600 hover:text-red-800" type="button" @click="removeBug(index)">
                              <i class="fa fa-times"></i></button>
                          </div>
                        </div>
                        <div class="flex flex-col mt-4">
                          <label class="text-gray-700 dark:text-gray-200" for="info">Informationen</label>
                          <textarea id="info"
                                    v-model="info"
                                    class="w-3/4 px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                                    style="height: 15rem"
                                    type="text">
                        </textarea>
                        </div>

                        <br>
                        <div>
                          <label class="text-gray-700 dark:text-gray-200" for="imgurl">Image-URL</label>
                          <input id="imgurl" v-model="img"
                                 class="block w-3/4 px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                                 type="text">

                        </div>
                      </div>
                    </div>


                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
              type="button"
              @click="submitted">
            Save
          </button>
          <button
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2  text-base font-medium hover:text-gray-900  hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              type="button"
              @click="showModal = false">
            Cancel
          </button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PluginEditModal",
  data() {
    return {
      showModal: false,
      newPlugin: false,
      plugin: {},
      files: [],
      versions: [],
      sendMessage: true,
      updates: [],
      bugs: [],
      info: "",
      img: "",
    }
  },
  methods: {
    show(plugin) {
      this.showModal = true
      this.newPlugin = false;
      this.plugin = {...plugin}
      this.loadVersions();
    },
    openCreate() {
      this.showModal = true
      this.newPlugin = true;
      this.plugin = {}
    },
    submitted() {

      fetch(
          this.$t('host') + (this.newPlugin ? '/plugins' : '/plugins/' + this.plugin.id),
          {
            method: this.newPlugin ? 'POST' : 'PATCH',
            headers: {
              'Authorization': localStorage.accesstoken,
              'Access-Control-Allow-Origin': '*',
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify(this.plugin)
          }
      )
          .then(res => {
                if (res.status == 200) {
                  this.showModal = false;
                  this.$emit("update")
                }
              }
          )
          .catch((e) => {
                console.log(e)
              }
          )

      if (this.sendMessage) {

        let object = {
          features: this.updates,
          bugs: this.bugs,
          info: this.info,
          plugin: this.plugin,
          img: this.img
        }

        fetch(
            this.$t('host') + '/sendUpdateMessage',
            {
              method: 'POST',
              headers: {
                'Authorization': localStorage.accesstoken,
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: JSON.stringify(object)
            }
        )
            .then(res => {
                  if (res.status == 200) {
                    this.showModal = false;
                    this.$emit("update")
                  }
                }
            )
            .catch((e) => {
                  console.log(e)
                }
            )
      }
    },
    loadFiles() {
      fetch(
          this.$t('host') + '/files',
          {
            method: 'GET',
            headers: {
              'Authorization': localStorage.accesstoken,
              'Access-Control-Allow-Origin': '*',
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
      )
          .then(res => {
                if (res.status == 200) {
                  return res.json();
                } else {
                  console.log(res.text())
                }
              }
          )
          .then(res => {
                this.files = res
              }
          )
          .catch((e) => {
                console.log(e)
              }
          )
    },
    loadVersions() {
      this.versions = [];
      fetch(
          this.$t('host') + '/files/' + this.plugin.name,
          {
            method: 'GET',
            headers: {
              'Authorization': localStorage.accesstoken,
              'Access-Control-Allow-Origin': '*',
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
      )
          .then(res => {
                if (res.status == 200) {
                  return res.json();
                } else {
                  console.log(res.text())
                }
              }
          )
          .then(res => {
                this.versions = res
              }
          )
          .catch((e) => {
                console.log(e)
              }
          )
    },
    removeUpdate(index) {
      this.updates.splice(index, 1)
    },
    addUpdate(e) {
      if (e.key === "Enter") {
        this.updates.push(document.querySelector('#featureInput').value)
        document.querySelector('#featureInput').value = ''
      }
    },
    removeBug(index) {
      this.bugs.splice(index, 1)
    },
    addBug(e) {
      if (e.key === "Enter") {
        this.bugs.push(document.querySelector('#bugInput').value)
        document.querySelector('#bugInput').value = ''
      }
    },
    updateSendMessage() {
      this.sendMessage = document.querySelector('#updateSendMessage').checked
      console.log(this.sendMessage)
    }
  },
  mounted() {
    this.loadFiles()
    this.updateSendMessage()
  }
}
</script>

<style scoped>

#popup {
  background-color: var(--bgCon);
  color: white;
}
</style>