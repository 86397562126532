<template>
  <InfoAlert ref="infoAlert"/>
  <center>
    <div class="usercard">
      <img :src="member.avatarUrl" alt="">
      <div class="userName">{{ member.username }}</div>
    </div>
  </center>

  <div class="smallcards">
    <InfoCard title="Verwarnungen" :content="warnCount" icon="fa-exclamation-triangle" color="#FF331F"></InfoCard>
    <InfoCard title="Tickets" :content="ticketCount" icon="fa-ticket" color="#ECFF5F"></InfoCard>
    <InfoCard title="Lizenzen" :content="licenseCount" icon="fa-key" color="#44F4FF"></InfoCard>
  </div>

    <ClientLicenses :licenses="this.licenses"/>
    <ClientTickets :tickets="this.tickets" />
  <ClientPunishments :user="this.$route.params.id"/>

</template>

<script>

import ClientPunishments from "@/components/admin/ClientPunishments";
import InfoCard from "@/components/ui/InfoCard";
import ClientLicenses from "@/components/admin/ClientLicenses";
import ClientTickets from "@/components/admin/ClientTickets";
import InfoAlert from "@/components/ui/alerts/InfoAlert";

export default {
  name: "User",
  components: {
    ClientPunishments,
    InfoCard,
    ClientLicenses,
    ClientTickets,
    InfoAlert
  },
  data() {
    return {
      member: {},
      warnCount: '???',
      ticketCount: '???',
      licenseCount: '???',
      licenses: undefined,
      tickets: undefined
    }
  },
  methods: {
    loadData() {
      fetch(
          this.$t('host') + '/admin/client/' + this.$route.params.id,
          {
            method: 'GET',
            mode: 'cors',
            headers: {
              'Authorization': localStorage.accesstoken,
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
          }
      ).then(
          res => {
            return res.json();
          }
      )
          .then(
              res => {
                this.member = res.member
                this.warnCount = res.warnCount
                this.ticketCount = res.ticketCount
                this.licenseCount = res.licenseCount
                this.licenses = res.licenses
                this.tickets = res.tickets
                this.$refs.infoAlert.showNow("Finished loading!")
              }
          )
          .catch(
              (e) => {
                console.log(e)
              }
          )
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>

<style scoped>
.usercard {
  display: flex;
  width: 32%;
  align-items: center;
  gap: 1rem;
  background-color: #1F2235;
  border-radius: 5px;
  transition: 500ms;
  margin-bottom: 1rem;
  color: var(--red);
}

.usercard .userName {
  margin-right: 1rem;
}

.usercard img {
  height: 5em;
  width: 5em;
  border-radius: 5px;
  margin: 1rem;
}
.smallcards {
  display: flex;
  gap: 10px;
  margin-bottom: 3rem;
  flex-wrap: wrap;
  flex-direction: row;
}

.smallcards *{
  width: 32%;
}
.cardrow {
   display: flex;
   gap: 1.5rem;
 }

</style>