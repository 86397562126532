<template>


  <div class="card">
    <div class="title">{{ $t('tickets.title') }}</div>
    <hr>
    <div class="card-content">
      <div class="flex flex-col">
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block py-2 min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden shadow-md sm:rounded-lg">

              <table class="min-w-full dark">
                <thead class=" dark:bg-gray-700">
                <tr>
                  <th scope="col"
                      class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase dark:text-gray-400">
                    {{ $t('tickets.table.head.name') }}
                  </th>
                  <th scope="col"
                      class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase dark:text-gray-400">
                    {{ $t('tickets.table.head.topic') }}
                  </th>
                  <th scope="col"
                      class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase dark:text-gray-400">
                    {{ $t('tickets.table.head.date') }}
                  </th>
                  <th scope="col"
                      class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase dark:text-gray-400">
                    {{ $t('tickets.table.head.view') }}
                  </th>
                </tr>
                </thead>
                <tbody>

                <tr
                    v-show="!tickets"
                    class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                    <div class="unloaded"></div>
                  </td>
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400 key ">
                    <div class="unloaded"></div>
                  </td>
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                    <div class="unloaded"></div>
                  </td>
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                    <div class="unloaded"></div>
                  </td>
                </tr>


                <tr :key="ticket.id" v-for="ticket in tickets"
                    class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                    ticket-{{
                      ticket.id
                    }}
                  </td>
                  <td
                      class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400 key ">{{
                      ticket.category === "" ? $t('tickets.table.noCategory') : ticket.category
                    }}
                  </td>
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">{{
                      ticket.channelID === -1 ? new Date(ticket.firstMessage).toLocaleDateString("de-DE") : $t('tickets.table.stillOpen')
                    }}
                  </td>
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                    <router-link :to="'/ticket/' + ticket.id" class="btn">{{ $t('tickets.table.viewTicket') }}</router-link>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
export default {
  name: "TicketsTable",
  props: {
    tickets: {type: Object},
  },
  mounted() {
  },
  methods: {

  }
}
</script>

<style scoped>

</style>