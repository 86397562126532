<template>
  <div class="card">
    <div class="title-row">
      <div class="title">Lizenzen verwallten</div>
      <form v-on:submit.prevent @submit="loadData">
        <input v-model="query" id="key" type="text"
               class="px-4 py-2 text-gray-700 bg-white border rounded-md sm:mx-2 dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
               placeholder="Search...">
      </form>
    </div>
    <hr>
    <div class="card-content">

      <table class="min-w-full dark">
        <thead class=" dark:bg-gray-700">
        <tr>
          <th scope="col"
              class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase dark:text-gray-400">
            {{ $t('licenses.table.plugin') }}
          </th>
          <th scope="col"
              class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase dark:text-gray-400">
            {{ $t('licenses.table.key') }}
          </th>
          <th scope="col"
              class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase dark:text-gray-400">
            {{ $t('licenses.table.uses') }}
          </th>
          <th scope="col"
              class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase dark:text-gray-400">
            {{ $t('licenses.table.expire') }}
          </th>
        </tr>
        </thead>
        <tbody>

        <tr
            v-show="licenses.length == 0"
            class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
          <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
            <div class="unloaded"></div>
          </td>
          <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400 key ">
            <div class="unloaded"></div>
          </td>
          <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
            <div class="unloaded"></div>
          </td>
          <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
            <div class="unloaded"></div>
          </td>
        </tr>


        <tr @click="openLicense(license.key)" :key="license.key" v-for="license in licenses"
            class="border-b cursor-pointer transition dark:hover:bg-red-500 dark:bg-gray-800 dark:border-gray-700">
          <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
            <i v-show="license.block" class="prefix fa fa-exclamation-triangle text-red-500"></i>
            <i v-show="license.watch" class="prefix fa fa-eye text-yellow-400"></i>
            {{ license.plugin }}
          </td>
          <td
              class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400 key ">{{
              license.key
            }}
          </td>
          <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">{{
              license.pings
            }}
          </td>
          <td :class="[ isTimeout(license.expiry) && license.expiry !== -1 ? 'blink' : 'dark:text-gray-400','py-4 px-6 text-sm whitespace-nowrap']">
            {{
              renderTime(license.expiry)
            }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <button class="edit bg-green-600" @click="openEdit"><i class="fa fa-plus"></i></button>
  <button class="edit2 bg-yellow-600" @click="$refs.many.show()"><i class="fa fa-repeat"></i></button>

  <LicenseAddModal ref="modal"/>
  <LicenseAddManyModal ref="many" />
</template>

<script>

import LicenseAddModal from "@/components/admin/LicenseAddModal";
import LicenseAddManyModal from "@/components/admin/LicenseAddManyModal";

export default {
  name: "LicenseManagement",
  components: {
    LicenseAddManyModal,
    LicenseAddModal,
  },
  data() {
    return {
      licenses: [],
      query: '',
    }
  },
  methods: {
    loadData(){
      fetch(
          this.$t('host') + '/licenses/search?query=' + this.query,
          {
            method: 'GET',
            mode: 'cors',
            headers: {
              'Authorization': localStorage.accesstoken,
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
          }
      )
          .then(
              res => {
                return res.json();
              }
          )
          .then(
              res => {
                this.licenses = res
              }
          )
          .catch(
              (e) => {
                console.log(e)
              }
          )
    },
    renderTime(timestamp){
      if(timestamp == -1){
        return this.$t('util.license.infinite')
      }else if(timestamp == 0){
        return this.$t('util.license.locked')
      }else{
        return new Date(timestamp).toLocaleDateString('de-DE') + ' ' + new Date(timestamp).toLocaleTimeString('de-DE')
      }
    },
    isTimeout(timestamp) {
      return timestamp < new Date().getTime();
    },
    openLicense(key){
      window.location.href = "/license/" + key
    },
    openEdit(){
      this.$refs.modal.show();
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>

<style scoped>

.title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-row > *:last-child {
  margin: 1rem;
}


.blink {
  animation: blink 2s;
  animation-iteration-count: infinite;
  color: rgb(156 163 175);
}

@keyframes blink {
  0% {
    color: rgb(156 163 175);
  }
  50%{
    color: var(--red);
  }
  100%{
    color: rgb(156 163 175);
  }
}

.edit {
  position: fixed;
  right: 2.7rem;
  bottom: 2.7rem;
  font-size: 1.5rem;
  border-radius: 5rem;
  transition: 250ms;
  width: 4rem;
  height: 4rem;
}

.edit:hover{
  transform: scale(1.1);
}
.edit2 {
  position: fixed;
  right: 2.7rem;
  bottom: 7rem;
  font-size: 1.5rem;
  border-radius: 5rem;
  transition: 250ms;
  width: 4rem;
  height: 4rem;
}

.edit2:hover{
  transform: scale(1.1);
}

</style>
