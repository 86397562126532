<template class="line-numbers">

  <prism language="json">{{ code }}</prism>
</template>

<script>

// yarn add prismjs
import Prism from 'vue-prism-component'

export default {
  name: "PasteView",
  components: {
    Prism
  },
  data() {
    return {
      code: "import { createApp } from 'vue'\n" +
          "import App from './App.vue'\n" +
          "import router from './router'\n" +
          "import i18n from './i18n'\n" +
          "import store from './store'\n" +
          "import './assets/tailwind.css'\n" +
          "import hljsVuePlugin from \"@highlightjs/vue-plugin\";\n" +
          "\n" +
          "createApp(App).use(hljsVuePlugin).use(store).use(i18n).use(router).mount('#app')",
    }
  },
  mounted() {
    Prism.highlightAll()
  }
}
</script>

<style scoped>

</style>