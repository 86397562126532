<template>

  <div class="card">
    <div class="title">Paste</div>
    <hr>
    <div class="card-content">


    </div>
  </div>
</template>

<script>
export default {
  name: "Paste"
}
</script>

<style scoped>

</style>