<template>
  <div v-show="showModal" class="fixed z-10 inset-0 overflow-y-auto " aria-labelledby="modal-title" role="dialog"
       aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 ">
      <div class="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div id="popup"
           class="inline-block align-bottom dark bg-dark rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div class=" px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div
                class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-gray-900 sm:mx-0 sm:h-10 sm:w-10">
              <i class="fa fa-plus"></i>
            </div>
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">

              <h3 class="text-lg leading-6 font-medium " id="modal-title">
                Lizenz erstellen
              </h3>
              <div class="mt-2">
                <div class="flex flex-col mt-8 space-y-3 sm:space-y-0 sm:flex-row sm:justify-start sm:-mx-2">
                  <form v-on:submit.prevent @submit="clicked">

                    <label class="text-gray-700 dark:text-gray-200" for="key">Key</label>

                    <div class="keyContainer">
                      <select id="prefix" v-model="keyPrefix"
                              class="block  px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring">
                        <option>TEAM</option>
                        <option>GIFT</option>
                        <option>CF</option>
                        <option>VIP</option>
                        <option>REPLACE</option>
                      </select>
                      <div
                          class="block px-4 py-2 mt-2 text-gray-700 bg-gray-900 border border-gray-200 rounded-md dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring">
                        -
                      </div>

                      <input id="key" v-model="key" type="text"
                             class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring">
                    </div>

                    <div class="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                      <div>
                        <label class="text-gray-700 dark:text-gray-200" for="plugin">Plugin</label>
                        <input :disabled="this.bound != 1" id="plugin" v-model="plName" type="text"
                               class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring">

                      </div>

                      <div>
                        <label class="text-gray-700 dark:text-gray-200" for="pluginBound">Plugin gebunden?</label>
                        <select id="pluginBound" v-model="bound"
                                class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring">
                          <option selected value="1">Ja</option>
                          <option value="0">Nein</option>
                        </select>
                      </div>

                      <div>
                        <label class="text-gray-700 dark:text-gray-200" for="locked">Gesperrt?</label>
                        <select id="locked" v-model="locked"
                                class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring">
                          <option value="1">Ja</option>
                          <option selected value="0">Nein</option>
                        </select>
                      </div>

                      <div>
                        <label  class="text-gray-700 dark:text-gray-200" for="plugin">Client (
                          {{ clientName }} )</label>
                        <input @keyup="clientChange" list="datalist" @blur="updateClient" id="client" v-model="plClient" type="text"
                               class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring">
                        <datalist id="datalist">
                          <option :key="suggestion.id" :value="suggestion.id" v-for="suggestion in clientSuggestions">{{suggestion.username}}</option>
                        </datalist>
                      </div>
                    </div>
                    <br>

                    <div>
                      <label class="text-gray-700 dark:text-gray-200" for="desc">Informationen</label>
                      <textarea id="desc" style="height: 15rem" v-model="plDesc" type="text"
                                class="w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring">
                        </textarea>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button @click="submitted" type="button"
                  class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm">
            {{ $t('licenses.add.save') }}
          </button>
          <button @click="showModal = false" type="button"
                  class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2  text-base font-medium hover:text-gray-900  hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
            {{ $t('licenses.add.abort') }}
          </button>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LicenseEditModal",
  data() {
    return {
      showModal: false,
      key: '',
      plClient: '',
      client: undefined,
      plName: '',
      expiry: -1,
      plDesc: '',
      plBound: true,
      bound: 1,
      locked: 0,
      keyPrefix: 'TEAM',
      clientName: '',
      clientSuggestions: [],
    }
  },
  props: {},
  methods: {
    randomString(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
      }
      return result;
    },
    show() {
      this.showModal = true
    },
    submitted() {

      let key = this.keyPrefix + '-' + this.key;

      let object = {
        key: key,
        plName: this.plName,
        plClient: this.plClient,
        plDesc: this.plDesc,
        plBound: this.bound == 1,
        expiry: this.locked ? 0 : -1
      }
      let body = JSON.stringify(object);
      console.log(body)

      fetch(
          this.$t('host') + '/license/' + key,
          {
            method: 'POST',
            headers: {
              'Authorization': localStorage.accesstoken,
              'Access-Control-Allow-Origin': '*',
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: body
          }
      )
          .then(res => {
                if (res.status == 200) {
                  this.showModal = false;
                  window.location.href = "/license/" + this.keyPrefix + '-' + this.key;
                }
              }
          )
          .catch((e) => {
                console.log(e)
              }
          )
    },
    updateClient() {
      fetch(
          this.$t('host') + '/info/userinfo/' + this.plClient,
          {
            method: 'GET',
            headers: {
              'Authorization': localStorage.accesstoken,
              'Access-Control-Allow-Origin': '*',
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
      )
          .then(res => {
                if (res.status == 200) {
                  return res.json();
                } else {
                  console.log(res.text())
                }
              }
          )
          .then(res => {
                this.clientName = res.username
              }
          )
          .catch(() => {
              }
          )
    },
    clientChange(){
      fetch(
          this.$t('host') + '/admin/client?query=' + this.plClient + '&limit=5',
          {
            method: 'GET',
            mode: 'cors',
            headers: {
              'Authorization': localStorage.accesstoken,
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
          }
      )
          .then(
              res => {
                return res.json();
              }
          )
          .then(
              res => {
                this.clientSuggestions = res.members
              }
          )
          .catch(
              (e) => {
                console.log(e)
              }
          )
    }
  },
  mounted() {
    this.key = this.randomString(4) + '-' + this.randomString(4) + '-' + this.randomString(4)
    console.log(this.key)
  }
}
</script>

<style scoped>

#popup {
  background-color: var(--bgCon);
  color: white;
}

.keyContainer {
  display: flex;
}

.keyContainer * {
  border-radius: 0;
}

.keyContainer *:first-child {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.keyContainer *:last-child {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
</style>