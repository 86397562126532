<template>

  <div class="flex dark flex-col w-64 h-screen px-4 py-8 border-r dark:bg-gray-800 dark:border-gray-600">
    <h2 class="text-3xl font-semibold accent">PrimeShop</h2>


    <div id="scroll" class="flex flex-col justify-between flex-1 mt-6">
      <nav>
        <div v-for="page in paths" v-show="page.list && ( !page.protected || (page.protected && isTrusted) )"
             :key="page.path">
          <hr v-show="page.splitBevore" class="my-6  dark:border-gray-600"/>

          <router-link
              :class="[page.path == $route.path ?
              'flex items-center px-4 py-2 mt-5 rounded-md dark:bg-gray-700 dark:text-gray-200' :
              'flex items-center px-4 py-2 mt-5  transition-colors duration-200 transform rounded-md dark:text-gray-400  dark:hover:bg-gray-700 dark:hover:text-gray-200 '
              , page.protected ? 'dark:text-red-500' : '']"
              :to="page.path">
            <i :class="['w-5 h-5', page.icon]"></i>
            <span class="mx-4 font-medium">{{ $t(page.displayPath) }}</span>
          </router-link>

        </div>
      </nav>
      <div class="flex items-center px-4 -mx-2 mt-3">
        <img class="object-cover mx-2 rounded-full h-9 w-9"
             :src="url"
             alt=""/>
        <h4 class="mx-2 font-medium dark:text-gray-200">{{ username }}</h4>
      </div>
      <hr class="my-6  dark:border-gray-600">
      <router-link class="dark:text-gray-400" to="/imprint">Impressum</router-link>
    </div>
  </div>
</template>

<script>
import {list} from "@/router";


export default {
  name: "Sidebar",
  components: {},
  props: {
    username: {type: String}
  },
  data() {
    return {
      paths: list,
      url: "",
      isTrusted: false,
    }
  },
  mounted() {
    if (localStorage.userdata) {
      let user = JSON.parse(localStorage.userdata)
      this.url = user.avatarurl
      this.isTrusted = user.trusted;
    }
  }
}
</script>

<style scoped>

#scroll {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#scroll::-webkit-scrollbar {
  display: none;
}
</style>