<template>

  <InfoAlert ref="infoAlert"/>
  <ErrorAlert ref="errorAlert"/>
  <SuccessAlert ref="successAlert"/>

  <LicenseAddModal @addLicense="addLicense" ref="modal"/>

  <div class="card">
    <div class="title-row">
      <div class="title">{{ $t('licenses.table.title') }}</div>
      <button @click="openModal()" class="btn green">{{ $t('licenses.table.add') }}</button>
    </div>
    <hr>
    <div class="card-content">
      <div class="flex flex-col">
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block py-2 min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden shadow-md sm:rounded-lg">

              <table class="min-w-full dark">
                <thead class=" dark:bg-gray-700">
                <tr>
                  <th scope="col"
                      class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase dark:text-gray-400">
                    {{ $t('licenses.table.plugin') }}
                  </th>
                  <th scope="col"
                      class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase dark:text-gray-400">
                    {{ $t('licenses.table.key') }}
                  </th>
                  <th scope="col"
                      class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase dark:text-gray-400">
                    {{ $t('licenses.table.uses') }}
                  </th>
                  <th scope="col"
                      class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase dark:text-gray-400">
                    {{ $t('licenses.table.expire') }}
                  </th>
                </tr>
                </thead>
                <tbody>

                <tr
                    v-show="!licenses"
                    class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                    <div class="unloaded"></div>
                  </td>
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400 key ">
                    <div class="unloaded"></div>
                  </td>
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                    <div class="unloaded"></div>
                  </td>
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                    <div class="unloaded"></div>
                  </td>
                </tr>


                <tr :key="license.key" v-for="license in licenses"
                    class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">{{
                      license.plugin
                                                                                                   }}
                  </td>
                  <td @click="copyKey(license.key)"
                      class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400 key ">{{
                      license.key
                    }}
                  </td>
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">{{
                      license.pings
                    }}
                  </td>
                  <td :class="[ isTimeout(license.expiry) && license.expiry !== -1 ? 'blink' : 'dark:text-gray-400','py-4 px-6 text-sm whitespace-nowrap']">
                    {{
                    renderTime(license.expiry)
                    }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>

import InfoAlert from "@/components/ui/alerts/InfoAlert";
import ErrorAlert from "@/components/ui/alerts/ErrorAlert";
import SuccessAlert from "@/components/ui/alerts/SuccessAlert";
import LicenseAddModal from "@/components/licenses/LicenseAddModal";

export default {
  name: "LicensesTable",
  components: {
    InfoAlert,
    LicenseAddModal,
    ErrorAlert,
    SuccessAlert
  },
  props: {
    licenses: {type: Object},
  },
  data() {
    return {}
  },
  methods: {
    renderTime(timestamp){
      if(timestamp == -1){
        return this.$t('util.license.infinite')
      }else if(timestamp == 0){
        return this.$t('util.license.locked')
      }else{
        return new Date(timestamp).toLocaleDateString('de-DE') + ' ' + new Date(timestamp).toLocaleTimeString('de-DE')
      }
    },
    isTimeout(timestamp) {
      return timestamp < new Date().getTime();
    },
    async copyKey(key) {
      await navigator.clipboard.writeText(key);
      this.$refs.infoAlert.showNow(this.$t('util.clipboardcopy'))
    },
    openModal() {
      this.$refs.modal.show()
    },
    addLicense(license) {
      fetch(this.$t('host') + '/info/user/license/' + license, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Authorization': localStorage.accesstoken,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      }).then(res => {
            if (res.status === 200 || res.status === 201) {
              this.$refs.successAlert.showNow(this.$t('licenses.add.success'))
              this.$emit("updateData")
            } else if (res.status === 409) {
              this.$refs.errorAlert.showNow(this.$t('licenses.add.alreadyExists'))
            } else if (res.status === 404) {
              this.$refs.errorAlert.showNow(this.$t('licenses.add.notFound'))
            }
          }
      ).catch((e) => {
        console.log(e)
      })
    }
  }

}
</script>

<style scoped>
.key {
  cursor: pointer;
}

.title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-row > *:last-child {
  margin: 1rem;
}

.blink {
  animation: blink 2s;
  animation-iteration-count: infinite;
  color: rgb(156 163 175);
}

@keyframes blink {
  0% {
    color: rgb(156 163 175);
  }
  50%{
    color: var(--red);
  }
  100%{
    color: rgb(156 163 175);
  }
}
</style>